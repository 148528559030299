export const MODALS = {
  ADD_COURSE_FORM_MODAL: "ADD_COURSE_FORM_MODAL",
  EDIT_COURSE_FORM_MODAL: "EDIT_COURSE_FORM_MODAL",
  COURSE_DELETE_MODAL: "COURSE_DELETE_MODAL",
  SECTION_FORM_MODAL: "SECTION_FORM_MODAL",
  SECTION_DELETE_MODAL: "SECTION_DELETE_MODAL",
  LESSON_FORM_MODAL: "LESSON_FORM_MODAL",
  LESSON_DELETE_MODAL: "LESSON_DELETE_MODAL",
  THEME_FORM_MODAL: "THEME_FORM_MODAL",
  THEME_DELETE_MODAL: "THEME_DELETE_MODAL"
};

export const ROOT_URL = process.env.REACT_APP_ROOT;

export const MEDIA_TYPE = {
  IMAGE: "IMAGE",
  VIDEO: "VIDEO"
};

export const ORGANIZATION_ROLES = [
  { label: "None", value: "" },
  {
    label: "Org Owner",
    value: "organization_owner"
  },
  { label: "Org Admin", value: "organization_admin" },
  { label: "User Admin", value: "organization_user" }
];

export const INSTRUCTIONAL_ROLES = [
  { label: "None", value: "" },
  { label: "Admin", value: "instructional_admin" },
  { label: "Designer", value: "instructional_designer" },
  { label: "Auditor", value: "instructional_auditor" }
];

export const VISUAL_ROLES = [
  { label: "None", value: "" },
  { label: "Admin", value: "visual_admin" },
  { label: "Designer", value: "visual_designer" },
  { label: "Auditor", value: "visual_auditor" }
];
