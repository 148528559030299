import React from "react";
import PropTypes from "prop-types";
import { TYPE_COURSE } from "../../../common/types";
import CourseForm from "./CourseForm";
import { useGetThemes } from "../../theme/ThemeList/graphql";
import Spinner from "../../shared/Spinner";

const CourseFormContainer = props => {
  const { loading: loadingTheme, error, data: themes } = useGetThemes();

  if (loadingTheme) {
    return <Spinner />;
  }

  if (error) {
    return `Error! ${error.message}`;
  }

  return <CourseForm {...props} themes={themes} />;
};

CourseFormContainer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  course: TYPE_COURSE,
  formRef: PropTypes.shape({})
};

CourseFormContainer.defaultProps = {
  course: null,
  formRef: null
};

export default CourseFormContainer;
