import React from "react";
import { Wrapper, Typography, Collapse } from "./styledComponents";
import UsersRolesForm from "../../components/user/UsersRolesForm";
import UserList from "../../components/user/UserList";
import useListSelection from "../../hooks/useListSelection";

const UsersPage = () => {
  const { selectedItems, selectItem, deselectItem, clear } = useListSelection();

  return (
    <Wrapper>
      <Collapse in={Boolean(selectedItems.length)}>
        <Typography variant="h6" component="h2">
          Role Assignment
        </Typography>
        <UsersRolesForm users={selectedItems} removeUsersSelection={clear} />
      </Collapse>
      <Typography variant="h6" component="h2" gutterBottom>
        Users
      </Typography>
      <UserList onSelect={selectItem} onDeselect={deselectItem} selectedUsers={selectedItems} />
    </Wrapper>
  );
};

export default UsersPage;
