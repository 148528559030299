import React, { useEffect, useRef, useState } from "react";
import { EditorBlock, EditorState } from "draft-js";
import { notEditable } from "../../constants";
import { getCurrentBlock } from "../../helpers";
import ImageInlineToolbar from "./ImageInlineToolbar";

import { Caption, Image, Wrapper } from "./styledComponents";

export default function LessonEditorBlockImage(props) {
  const {
    block,
    blockProps: { setEditorState, editorState, addAsset, removeAsset }
  } = props;

  const src = block.getData().get("src");
  const caption = block.getData().get("caption");

  const [showInlineToolbar, setShowInlineToolbar] = useState(false);
  const imgRef = useRef(null);

  const currentBlock = getCurrentBlock(editorState);

  const isCurrentBlockSelected =
    currentBlock.getKey() === block.getKey() && Boolean(imgRef.current);

  useEffect(() => {
    setShowInlineToolbar(isCurrentBlockSelected);
  }, [isCurrentBlockSelected]);

  const onImageClick = mouseEvent => {
    mouseEvent.preventDefault();
    if (isCurrentBlockSelected) {
      return;
    }
    const newSelectionState = editorState.getSelection().merge({
      anchorKey: block.getKey(),
      anchorOffset: block.getLength(),
      focusKey: block.getKey(),
      focusOffset: block.getLength(),
      isBackward: false
    });

    setEditorState(EditorState.forceSelection(editorState, newSelectionState));
  };

  useEffect(() => {
    // only Firebase asset add to list
    if (src.startsWith("asset/")) {
      addAsset(src);
    }

    return () => removeAsset(src);
  }, [addAsset, removeAsset, src]);

  return (
    <Wrapper>
      <div {...notEditable}>
        <ImageInlineToolbar
          referenceElement={imgRef.current}
          imageBlockKey={block.getKey()}
          show={showInlineToolbar}
          placement="top"
        />
        <Image
          src={src}
          alt={caption}
          data-selected={isCurrentBlockSelected}
          ref={imgRef}
          onMouseDown={onImageClick}
        />
      </div>
      <Caption>
        <EditorBlock {...props} />
      </Caption>
    </Wrapper>
  );
}
