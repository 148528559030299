import React from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/styles";
import {IconButton} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import MoveIcon from "@material-ui/icons/DragIndicator";
import {Link} from "@reach/router";
import Divider from "@material-ui/core/Divider";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  item: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%"
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main
  }
}));

const LessonListItem = ({ lesson, openForm, confirmDelete }) => {
  const classes = useStyles();

  const { id: lessonId, courseId, sectionId, title, description } = lesson;

  return (
    <div>
      <Divider light className={classes.divider} />
      <div className={classes.wrapper}>
        <div className={classes.item}>
          <Typography gutterBottom variant="h5">
            <Link
              to={`/courses/${courseId}/${sectionId}/${lessonId}/content`}
              className={classes.link}
            >
              {title}
            </Link>
          </Typography>
          <div>
            <IconButton onClick={() => openForm(lesson)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => confirmDelete(lesson)}>
              <DeleteIcon />
            </IconButton>
            <IconButton>
              <MoveIcon />
            </IconButton>
          </div>
        </div>
        <Typography component="div">{description}</Typography>
      </div>
    </div>
  );
};

LessonListItem.propTypes = {
  lesson: PropTypes.shape({}).isRequired,
  openForm: PropTypes.func.isRequired,
  confirmDelete: PropTypes.func.isRequired
};

export default LessonListItem;
