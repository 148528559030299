import gql from "graphql-tag";
import {courseDetailsFragment} from "../graphql";
import {useMutation} from "@apollo/react-hooks";

export const UPDATE_COURSE = gql`
  mutation UpdateCourse($course: CourseInput!) {
    updateCourse(course: $course) {
      ...courseDetails
    }
  }
  ${courseDetailsFragment}
`;

export const useUpdateCourseMutation = () => {
    const [updateCourse] = useMutation(UPDATE_COURSE);
    return async course =>
        await updateCourse({
            variables: { course },
            update: proxy => {
                proxy.writeData({
                    data: {
                        selectedCourseId: null
                    }
                });
            }
        });
};