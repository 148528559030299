import PeopleIcon from "@material-ui/icons/People";
import AddCircle from "@material-ui/icons/AddCircle";
import ColorLens from "@material-ui/icons/ColorLens";
import HomeIcon from "@material-ui/icons/Home";

import React from "react";

export const sidebarItems = [
  { id: "Home", icon: <HomeIcon />, url: "/" },
  { id: "Users", icon: <PeopleIcon />, url: "/users" },
  { id: "Theme", icon: <ColorLens />, url: "/themes" },
  { id: "Courses", icon: <AddCircle />, url: "/courses" }
];
