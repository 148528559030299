import React from "react";
import {Link} from "@reach/router";
import {Divider, List, ListItem, ListItemIcon, ListItemText, makeStyles} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import Star from "@material-ui/icons/Star";
import {ExpandLess, ExpandMore} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(2)
  },
  sectionTitle: {
    fontSize: ".5rem"
  },
  sectionHeader: {
    backgroundColor: theme.palette.paper
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main
  }
}));

const CourseNavSection = ({ courseId, section }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  function handleClick() {
    setOpen(!open);
  }

  return (
    <>
      <ListItem button onClick={handleClick} className={classes.sectionHeader}>
        <ListItemText
          className={classes.listItemText}
          primary={<span>{section.title}</span>}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Divider />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {section.lessons.map(item => (
            <Link
              to={`/courses/${courseId}/${section.id}/${item.id}/content`}
              key={item.id}
              className={classes.link}
            >
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <Star />
                </ListItemIcon>
                <ListItemText primary={<span>{item.title}</span>} />
              </ListItem>
            </Link>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default CourseNavSection;
