import { useCreateThemeMutation, useUpdateThemeMutation } from "./graphql";
import { useOpenModal } from "../../shared/Modal/graphql";
import { useSelectedTheme } from "../graphql";
import { MODALS } from "../../../common/constants";
import ThemeForm from "./ThemeForm";
import React from "react";
import { navigate } from "@reach/router";

const modalId = MODALS.THEME_FORM_MODAL;

const ThemeFormContainer = () => {
  const createThemeMutation = useCreateThemeMutation();
  const updateThemeMutation = useUpdateThemeMutation();
  const openModal = useOpenModal();
  const theme = useSelectedTheme();

  const onCancel = () => openModal(modalId, false);

  const isUpdating = theme && Boolean(theme.id);

  const modalTitle = isUpdating ? `Edit theme ${theme.name}` : `Add new theme`;
  const submitButtonLabel = isUpdating ? "Update" : "Add";

  const createTheme = async theme => {
    const newTheme = await createThemeMutation(theme);
    if (newTheme) {
      onCancel();
      navigate(`/themes/${newTheme.id}`);
    }
    return newTheme;
  };

  const updateTheme = async theme => {
    const updatedTheme = await updateThemeMutation(theme);
    if (updatedTheme) {
      onCancel();
    }
    return updatedTheme;
  };

  const onSubmit = isUpdating ? updateTheme : createTheme;

  return (
    <ThemeForm
      modalId={modalId}
      modalTitle={modalTitle}
      onSubmit={onSubmit}
      onCancel={onCancel}
      submitButtonLabel={submitButtonLabel}
      theme={theme}
    />
  );
};

export default ThemeFormContainer;
