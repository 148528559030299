import gql from "graphql-tag";
import {themeDetailsFragment, useSelectThemeMutation} from "../graphql";
import {useMutation, useQuery} from "@apollo/react-hooks";

export const GET_THEME = gql`
  query GetTheme($id: ID!) {
    theme(id: $id) {
      ...themeDetails
    }
  }
  ${themeDetailsFragment}
`;

export const useGetTheme = id => {
    const selectTheme = useSelectThemeMutation();
    const { data, loading, error } = useQuery(GET_THEME, {
        variables: { id }
    });

    if (!loading && data && data.theme) {
        selectTheme(data.theme);
    }
    return { data: data ? data.theme : data, loading, error };
};

export const UPDATE_THEME_CONTENT = gql`
  mutation UpdateThemeContent($themeContent: ThemeContentInput!) {
    updateThemeContent(themeContent: $themeContent) {
      ...themeDetails
    }
  }
  ${themeDetailsFragment}
`;

export const useUpdateThemeContentMutation = () => {
    const [updateThemeContent] = useMutation(UPDATE_THEME_CONTENT);
    return async themeContent =>
        await updateThemeContent({ variables: { themeContent } });
};
