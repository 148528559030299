import {AtomicBlockUtils, EditorState} from "draft-js";
import {useLessonEditor} from "../LessonEditorContext";

const useInsertAtomicBlock = () => {
  const { editorState, setEditorState } = useLessonEditor();

  return (atomicType, mutability, data) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithNewEntity = contentState.createEntity(
      atomicType,
      mutability,
      data
    );
    const entityKey = contentStateWithNewEntity.getLastCreatedEntityKey();

    const newEditorState = EditorState.push(
      editorState,
      contentState,
      "change-block-type"
    );
    setEditorState(
      AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ")
    );
  };
};

export default useInsertAtomicBlock;
