import React from "react";
import PropTypes from "prop-types";
import Modal from "../../shared/Modal";
import {Box, Typography} from "@material-ui/core";
import {preventDefault} from "../../../common/util";
import {TYPE_COURSE} from "../../../common/types";
import Overlay from "../../shared/Overlay";

const CourseConfirmDeleteDialog = props => {
  const { modalId, onConfirm, onCancel, course, loading } = props;
  const handleConfirm = preventDefault(() => onConfirm());
  const handleCancel = preventDefault(() => onCancel());

  const { title, description } = course || {};

  return (
    <Modal
      modalId={modalId}
      title="Are you sure you want to delete course?"
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      confirmButtonLabel="Yes"
      cancelButtonLabel="No"
      maxWidth="md"
      fullWidth
    >
      <Typography component="span">
        <Box fontWeight="fontWeightBold">{title}</Box>
      </Typography>
      <Typography component="p">{description}</Typography>
      {loading && <Overlay />}
    </Modal>
  );
};

CourseConfirmDeleteDialog.propTypes = {
  modalId: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  course: TYPE_COURSE,
  loading: PropTypes.bool.isRequired
};

CourseConfirmDeleteDialog.defaultProps = {
  course: null
};

export default CourseConfirmDeleteDialog;
