import React from "react";
import PropTypes from "prop-types";
import {
  FormHelperText,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import { TYPE_OPTIONS } from "../../../common/types";
import {FormControl} from "./styledComponents";

const buildRadioItems = options =>
  options.map(({ label, value }, index) => (
    <FormControlLabel
      key={index}
      value={value}
      control={<Radio color="primary" />}
      label={label}
    />
  ));

const RadioField = props => {
  const { label, error, options, ...radioProps } = props;

  const items = buildRadioItems(options);

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup aria-label={label} {...radioProps}>
        {items}
      </RadioGroup>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

RadioField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  options: TYPE_OPTIONS
};

RadioField.defaultProps = {
  error: null,
  options: []
};

export default RadioField;
