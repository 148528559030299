import React from "react";
import PropTypes from "prop-types";
import FormField from "./FormField";
import { MenuItem, Select } from "@material-ui/core";
import {TYPE_OPTIONS} from "../../../common/types";

const buildMenuItems = options => {
  const { items, selected, required } = options;

  const menuItems = [];

  if (!required) {
    menuItems.push(<MenuItem key={0} value={null} selected={!selected} />);
  }

  items.forEach(({ label, value }, index) => {
    const isSelected = value === selected;
    menuItems.push(
      <MenuItem key={index + 1} value={value} selected={isSelected}>
        {label}
      </MenuItem>
    );
  });

  return menuItems;
};

const SelectField = props => {
  const { label, error, options, required, ...selectProps } = props;
  return (
    <FormField label={label} name={selectProps.name} error={error}>
      <Select {...selectProps}>
        {buildMenuItems({
          items: options,
          selected: selectProps.value,
          required
        })}
      </Select>
    </FormField>
  );
};

SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: TYPE_OPTIONS,
  error: PropTypes.string,
  required: PropTypes.bool
};

SelectField.defaultProps = {
  options: [],
  error: null,
  required: false
};

export default SelectField;
