import React from "react";
import PropTypes from "prop-types";
import { InputLabel, FormHelperText } from "@material-ui/core";
import { FormControl } from "./styledComponents";

const FormField = props => {
  const { label, error, name, children } = props;
  return (
    <FormControl error={!!error}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      {children}
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

FormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  children: PropTypes.node
};

FormField.defaultProps = {
  error: null
};

export default FormField;
