import React from "react";
import {VideoWrapper} from "./styledComponents";
import {ROOT_URL} from "../../../common/constants";
import {useFirebase} from "../../firebase";

const Video = (props, ref) => {
  const { src, ...rest } = props;

  const firebase = useFirebase();

  const videoUrl = `${ROOT_URL}/${src}?token=${firebase.getToken()}`;

  return (
    <VideoWrapper>
      <video src={videoUrl} {...rest} ref={ref}>
        Video not supported
      </video>
    </VideoWrapper>
  );
};

export default React.forwardRef(Video);
