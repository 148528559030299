import React, { useState } from "react";
import { TYPE_COURSE } from "../../../common/types";
import CourseSettings from "./CourseSettings";
import { handleSCORMDownload } from "./utils";
import { MODALS } from "../../../common/constants";
import { useFirebase } from "../../firebase";
import { useSelectCourseMutation } from "../graphql";
import { useOpenModal } from "../../shared/Modal/graphql";
import {useUpdateCourseMutation} from "../EditCourseForm/graphql";

const CourseSettingsContainer = props => {
  const { course } = props;

  const firebase = useFirebase();
  const updateCourse = useUpdateCourseMutation();
  const selectCourse = useSelectCourseMutation();
  const openModal = useOpenModal();
  const [SCORMDownloading, setSCORMDownloading] = useState(false);

  const onSCORMDownload = handleSCORMDownload(
    firebase.getToken(),
    course.id,
    setSCORMDownloading
  );

  const onArchive = () => {
    selectCourse(course);
    openModal(MODALS.COURSE_DELETE_MODAL, true);
  };

  return (
    <CourseSettings
      course={course}
      updateCourse={updateCourse}
      onArchive={onArchive}
      onSCORMDownload={onSCORMDownload}
      SCORMDownloading={SCORMDownloading}
    />
  );
};

CourseSettingsContainer.propTypes = {
  course: TYPE_COURSE
};

export default CourseSettingsContainer;
