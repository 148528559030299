import { styled } from "@material-ui/styles";
import { Paper as PaperMUI } from "@material-ui/core";
import GoogleIconBase from "../../icons/GoogleIcon";

export const Wrapper = styled("div")(() => ({
  display: "flex",
  height: "100vh",
  justifyContent: "center",
  alignItems: "center"
}));

export const LoginPanel = styled(PaperMUI)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: theme.spacing(4),
  height: "200px"
}));

export const GoogleIcon = styled(GoogleIconBase)(({ theme }) => ({
  marginRight: theme.spacing(1)
}));
