import React from "react";
import PropTypes from "prop-types";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Title} from "./styledComponents";

const ThemeAccordion = props => {
  const { panelName, title, expanded, onChange, children } = props;

  return (
    <ExpansionPanel expanded={expanded} onChange={onChange}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${panelName}-content`}
        id={`${panelName}-header`}
      >
        <Title>{title}</Title>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

ThemeAccordion.propTypes = {
  panelName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ThemeAccordion;
