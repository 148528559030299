import MUIPaper from "@material-ui/core/Paper";
import MUIBox from "@material-ui/core/Box";
import {withStyles} from "@material-ui/styles";

export const Paper = withStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2)
  }
}))(MUIPaper);

export const Wrapper = withStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(6)
  }
}))(MUIBox);
