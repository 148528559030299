import gql from "graphql-tag";
import { useApolloClient, useQuery } from "@apollo/react-hooks";

export const courseDetailsFragment = gql`
  fragment courseDetails on Course {
    id
    title
    description
    themeId
  }
`;

export const SELECTED_COURSE_ID = gql`
  query SelectedCourseId {
    selectedCourseId @client
  }
`;

export const useSelectedCourse = () => {
  const client = useApolloClient();
  const {
    data: { selectedCourseId }
  } = useQuery(SELECTED_COURSE_ID);
  return client.readFragment({
    id: `Course:${selectedCourseId}`,
    fragment: courseDetailsFragment,
    fragmentName: "courseDetails"
  });
};

export const useSelectCourseMutation = () => {
  const client = useApolloClient();
  return course => {
    const { id: selectedCourseId } = course || { id: null };
    client.writeData({
      data: {
        selectedCourseId,
        selectedSectionId: null,
        selectedLessonId: null
      }
    });
  };
};
