import React from "react";
import { TYPE_NAV_ITEMS } from "../../../../common/types";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Link } from "@reach/router";

export const buildStyle = options => {
  const { isCurrent, isPartiallyCurrent, href } = options;

  const isActive = href === "/" ? isCurrent : isPartiallyCurrent;

  const color = isActive ? "#4fc3f7" : "inherit";

  return {
    style: {
      color
    }
  };
};

const NavItems = props => {
  const { items } = props;

  const listItems = items.map(({ id, icon, url }) => (
    <ListItem
      key={id}
      button
      component={React.forwardRef((props, ref) => (
        <Link to={url} innerRef={ref} {...props} getProps={buildStyle} />
      ))}
      dense
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={id} />
    </ListItem>
  ));

  return <List>{listItems}</List>;
};

NavItems.propTypes = {
  items: TYPE_NAV_ITEMS.isRequired
};

export default NavItems;
