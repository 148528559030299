import { createNewBlock } from "../helpers";
import { Map } from "immutable";
import { BLOCK_TYPES } from "../constants";

const onSave = options => data => {
  const { insertNewBlock, onClose, mediaType } = options;

  const { caption } = data;

  const newBlock = createNewBlock(BLOCK_TYPES[mediaType], caption, Map(data));

  insertNewBlock(newBlock, true);
  onClose();
};

export default onSave;
