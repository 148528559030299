import React from "react";
import PropTypes from "prop-types";
import { TYPE_USER } from "../../../../common/types";
import {
  TableCell,
  TableRow,
  Checkbox,
  CheckboxCell
} from "./styledComponents";
import UserRoleChip from "../../UserRoleChip";
import onUserSelect from "./onUserSelect";

const UserListItem = props => {
  const { user, selected, onSelect, onDeselect, onRoleRemove } = props;

  const { displayName, email, roles } = user;

  const roleChips = (roles || []).map((role, index) => (
    <UserRoleChip
      role={role}
      key={index}
      onDelete={onRoleRemove({ user, role })}
    />
  ));

  return (
    <TableRow selected={selected}>
      <CheckboxCell>
        <Checkbox
          checked={selected}
          onChange={onUserSelect({ user, onSelect, onDeselect, selected })}
          inputProps={{
            "aria-label": `select ${displayName}`
          }}
        />
      </CheckboxCell>
      <TableCell>{displayName}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{roleChips}</TableCell>
    </TableRow>
  );
};

UserListItem.propTypes = {
  user: TYPE_USER.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  onRoleRemove: PropTypes.func.isRequired
};

UserListItem.defaultProps = {
  selected: false
};

export default UserListItem;
