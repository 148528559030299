import React from "react";
import ActionButton from "./ActionButton";
import {TitleTwoTone} from "@material-ui/icons";
import {useToggleBlockType} from "../hooks";

export default function ConvertToSubtitle() {
  const toggleBlockType = useToggleBlockType();
  return (
    <ActionButton
      label={"Convert to subtitle"}
      action={() => toggleBlockType("header-three")}
      icon={TitleTwoTone}
    />
  );
}
