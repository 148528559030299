import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { TextField } from "../../shared/FormFields";
import Modal from "../../shared/Modal";
import { preventDefault } from "../../../common/util";
import useForm from "../../../hooks/useForm";
import { buildFormValues, validateSectionForm } from "./utils";
import Overlay from "../../shared/Overlay";

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%"
  }
}));

const SectionForm = props => {
  const {
    modalId,
    modalTitle,
    onSubmit,
    onCancel,
    submitButtonLabel,
    cancelButtonLabel,
    section
  } = props;

  const classes = useStyles();

  const defaultValues = useMemo(() => buildFormValues(section), [section]);

  const form = useForm({
    defaultValues,
    submitHandler: onSubmit,
    validatorHandler: validateSectionForm,
    reinitialize: !Boolean(defaultValues.id)
  });

  const handleSubmit = preventDefault(async () => {
    const processed = await form.submit();
    if (processed) {
      onCancel();
    }
  });

  const handleCancel = preventDefault(() => {
    if (form.isSubmitting) {
      return;
    }
    onCancel();
    form.reset();
  });

  return (
    <Modal
      modalId={modalId}
      title={modalTitle}
      onConfirm={handleSubmit}
      onCancel={handleCancel}
      confirmButtonLabel={submitButtonLabel}
      cancelButtonLabel={cancelButtonLabel}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit} className={classes.form}>
        <TextField label="Title" {...form.field("title")} autoFocus />
      </form>
      {form.isSubmitting && <Overlay />}
    </Modal>
  );
};

SectionForm.propTypes = {
  modalId: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  section: PropTypes.shape({})
};

SectionForm.defaultProps = {
  section: null
};

export default SectionForm;
