import CourseListItem from "./CourseListItem";
import React from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import AddCourseForm from "../AddCourseForm";

const useStyles = makeStyles(theme => ({
  list: {
    display: "flex",
    flexDirection: "column"
  },
  listActions: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "16px",
    justifyContent: "flex-end"
  }
}));

const CourseList = ({ courses, openForm }) => {
  const classes = useStyles();

  const coursesList = courses.map(course => (
    <CourseListItem
      key={course.id}
      course={course}
      openForm={openForm}
    />
  ));

  return (
    <div className={classes.list}>
      <div className={classes.listActions}>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={() => openForm()}
        >
          New Course
        </Button>
      </div>
      {coursesList}
      <AddCourseForm />
    </div>
  );
};

CourseList.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  openForm: PropTypes.func.isRequired
};

export default CourseList;
