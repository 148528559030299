import PropTypes from "prop-types";
import { MEDIA_TYPE } from "./constants";

export const TYPE_COURSE_SETTINGS = PropTypes.shape({
  themeId: PropTypes.string
});

export const TYPE_COURSE = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  settings: TYPE_COURSE_SETTINGS
});

export const TYPE_FORM = PropTypes.shape({
  formValues: PropTypes.shape({}),
  setFormValues: PropTypes.func.isRequired,
  formErrors: PropTypes.shape({}),
  setFormErrors: PropTypes.func.isRequired,
  hasErrors: PropTypes.func.isRequired,
  field: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired
});

export const TYPE_THEME_STYLE = PropTypes.shape({
  headerFont: PropTypes.string,
  bodyFont: PropTypes.string,
  quoteFont: PropTypes.string,
  colorPrimary: PropTypes.string,
  colorSecondary: PropTypes.string
});

export const TYPE_THEME = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  headerLeft: PropTypes.string,
  headerRight: PropTypes.string,
  footerLeft: PropTypes.string,
  footerRight: PropTypes.string,
  styles: TYPE_THEME_STYLE
});

export const TYPE_LESSON = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  transcript: PropTypes.string,
  theme: TYPE_THEME
});

export const TYPE_NAV_ITEM = PropTypes.shape({
  id: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired
});

export const TYPE_NAV_ITEMS = PropTypes.arrayOf(TYPE_NAV_ITEM);

export const TYPE_OPTIONS = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })
);

export const TYPE_USER = PropTypes.shape({});

export const TYPE_USERS = PropTypes.arrayOf(TYPE_USER);

export const TYPE_MEDIA_TYPE = PropTypes.oneOf(Object.values(MEDIA_TYPE));
