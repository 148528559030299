import React, { useState } from "react";
import { MODALS } from "../../../common/constants";
import CourseConfirmDeleteDialog from "./CourseConfirmDeleteDialog";
import { useOpenModal } from "../../shared/Modal/graphql";
import { useDeleteCourseMutation } from "./graphql";
import { useSelectedCourse } from "../graphql";
import {navigate} from "@reach/router";

const modalId = MODALS.COURSE_DELETE_MODAL;

const CourseConfirmDeleteDialogContainer = () => {
  const deleteCourse = useDeleteCourseMutation();
  const openModal = useOpenModal();
  const course = useSelectedCourse();
  const [loading, setLoading] = useState(false);

  const onCancel = () => {
    if (loading) {
      return;
    }
    openModal(modalId, false);
  };

  const onConfirm = async () => {
    setLoading(true);
    await deleteCourse(course.id);
    setLoading(false);
    onCancel();
    navigate('/courses');
  };

  return (
    <CourseConfirmDeleteDialog
      modalId={modalId}
      onConfirm={onConfirm}
      onCancel={onCancel}
      course={course}
      loading={loading}
    />
  );
};

export default CourseConfirmDeleteDialogContainer;
