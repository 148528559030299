import {useBreadcrumbs} from "./graphql";
import Spinner from "../../../components/shared/Spinner";
import React from "react";
import Breadcrumbs from "./Breadcrumbs";

const BreadcrumbsContainer = () => {
  const { data, loading, error } = useBreadcrumbs();

  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return null;
  }

  return <Breadcrumbs items={data} />;
};

export default BreadcrumbsContainer;
