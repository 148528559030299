export const PAGE_NAMES = {
  HOME: "HOME",
  COURSES: "COURSES",
  COURSE: "COURSE",
  NEW_COURSE: "NEW_COURSE",
  LESSON: "LESSON",
  THEME: "THEME",
  ROLES: "ROLES",
  USERS: "USERS"
};
