import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { SelectField, TextField } from "../../shared/FormFields";
import { Form } from "./styledComponents";
import useForm from "../../../hooks/useForm";
import { buildFormValues, validateCourseForm } from "./utils";
import { TYPE_COURSE, TYPE_THEME } from "../../../common/types";
import Overlay from "../../shared/Overlay";
import { Button } from "../../theme/ThemeEditor/styledComponents";

const CourseForm = props => {
  const { course, themes, onSubmit, formRef, showSave } = props;

  const defaultValues = useMemo(() => buildFormValues(course), [course]);

  const form = useForm({
    defaultValues,
    submitHandler: onSubmit,
    validatorHandler: validateCourseForm,
    reinitialize: !Boolean(defaultValues.id),
    formRef
  });

  return (
    <Form onSubmit={onSubmit}>
      <TextField label="Title" {...form.field("title")} fullWidth autoFocus />
      <TextField label="Description" {...form.field("description")} fullWidth />
      <SelectField
        label="Theme"
        {...form.field("themeId")}
        options={themes.map(({ id, name }) => ({ label: name, value: id }))}
        selected={form.formValues.themeId}
        fullWidth
        required
      />
      {showSave && (
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={() => form.submit()}
        >
          Save
        </Button>
      )}
      {form.isSubmitting && <Overlay />}
    </Form>
  );
};

CourseForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  course: TYPE_COURSE,
  themes: PropTypes.arrayOf(TYPE_THEME).isRequired,
  formRef: PropTypes.shape({}),
  showSave: PropTypes.bool
};

CourseForm.defaultProps = {
  course: null,
  formRef: null,
  showSave: false
};

export default CourseForm;
