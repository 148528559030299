import React from "react";
import PropTypes from "prop-types";
import {IconButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
  button: {},
  icons: {
    color: "white"
  }
});

export default function ActionButton(props) {
  const { icon: Icon, label, action } = props;
  const classes = useStyles();

  const onMouseDown = mouseEvent => {
    mouseEvent.preventDefault();
    action();
  };

  return (
    <IconButton
      className={classes.button}
      aria-label={label}
      onMouseDown={onMouseDown}
    >
      <Icon className={classes.icons} />
    </IconButton>
  );
}

ActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  icon: PropTypes.any.isRequired
};
