import React from "react";
import { useGetCourses } from "./graphql";
import CourseList from "./CourseList";
import Spinner from "../../shared/Spinner";
import { MODALS } from "../../../common/constants";
import { useOpenModal } from "../../shared/Modal/graphql";
import { useSelectCourseMutation } from "../graphql";

const CourseListContainer = () => {
  const selectCourse = useSelectCourseMutation();
  const openModal = useOpenModal();
  const { data, error, loading } = useGetCourses();

  const openForm = (course = null) => {
    selectCourse(course);
    openModal(MODALS.ADD_COURSE_FORM_MODAL, true);
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return `Error! ${error.message}`;
  }

  return (
    <CourseList
      courses={data}
      openForm={openForm}
    />
  );
};

export default CourseListContainer;
