import { createMuiTheme } from "@material-ui/core";
import blue from "@material-ui/core/colors/blue";

export const theme = createMuiTheme({
  dimensions: {
    sidebar: "260px"
  },
  palette: {
    primary: blue,
    paper: "#ecf0f1",
    lightColor: "#2196f3",
    paperColor: "#ecf0f1"
  },
  overrides: {
    MuiAvatar: {
      root: {
        width: 32,
        height: 32
      }
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "#FFFFFF"
      }
    },
    MuiListSubheader: {
      root: {
        lineHeight: 1.65,
        padding: "16px 0"
      }
    },
    MuiListItem: {
      root: {
        padding: "16px 0"
      }
    },
    MuiListItemText: {
      primary: {
        lineHeight: 1.5,
        fontSize: ".95rem"
      }
    }
  }
});
