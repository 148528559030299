import React from "react";
import PropTypes from "prop-types";
import { Chip } from "./styledComponents";

const UserRoleChip = props => {
  const { role, ...rest } = props;
  return <Chip label={role} size="small" {...rest} />;
};

UserRoleChip.propTypes = {
  role: PropTypes.string.isRequired
};

export default UserRoleChip;
