import { makeStyles } from "@material-ui/core";
import { useMemo } from "react";

export const DEFAULT_COURSE_STYLES = {
  headerFont: "inherit",
  bodyFont: "inherit",
  quoteFont: "inherit",
  colorPrimary: "#1e90ff",
  colorSecondary: "#1e90ff"
};

// TODO this object should be returned from backend
// in order to be consistent on frontend and SCORM
export const prepareCourseStyles = (styles = DEFAULT_COURSE_STYLES) => ({
  root: {
    marginLeft: "48px"
  },
  main: {
    padding: "16px 32px"
  },
  header: {
    backgroundColor: styles.colorPrimary,
    padding: "16px",
    color: "#fff",
    fontFamily: styles.headerFont
  },
  footer: {
    backgroundColor: styles.colorSecondary,
    padding: "8px 24px",
    color: "#fff",
    fontSize: "12px"
  },
  p: {
    marginBottom: "24px",
    fontFamily: styles.bodyFont
  },
  quote: {
    textAlign: "center",
    paddingBottom: "24px",
    fontStyle: "italic",
    fontFamily: styles.quoteFont
  },
  middleFooter: {
    textAlign: "center"
  },
  rightFooter: {
    textAlign: "right"
  },
  lessonName: {
    padding: "0px 16px"
  }
});

// since material-UI does not provide convenient function for generating class
// this hook wraps hook created with makeStyles
// it memoize theme values in order to prevent infinitive re-rendering
export const useBuildCourseTheme = themeStyles => {
  const useThemeStyles = useMemo(
    () => makeStyles(prepareCourseStyles(themeStyles)),
    [themeStyles]
  );
  return useThemeStyles();
};
