import get from "lodash.get";

const handleSubmit = options => {
  const { onSubmit, uploadFile } = options;

  return async values => {
    const { caption, align, src, file } = values;

    const currentSrc = get(file, "content", null)
      ? await uploadFile({ ...file, caption })
      : src;

    onSubmit({
      src: currentSrc,
      caption,
      align
    });
  };
};

export default handleSubmit;
