import {ROOT_URL} from "../../../common/constants";

export const handleSCORMDownload = (token, courseId, setLoading) => async () => {
  setLoading(true);

  const response = await fetch(`${ROOT_URL}/scorm/${courseId}`, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + token
    })
  });

  if (!response.ok) {
    // TODO show exception modal
    console.error(response.statusText);
    setLoading(false);
    return;
  }

  const scorm = await response.blob();

  const url = window.URL.createObjectURL(scorm);
  const a = document.createElement("a");
  a.href = url;
  a.download = "scorm.zip";
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove();

  setLoading(false);
};
