import React from "react";
import PropTypes from "prop-types";
import {
  useAddBreadcrumb,
  useClearBreadcrumbs
} from "../layouts/UserLayout/Breadcrumbs/graphql";
import UserLayout from "../layouts/UserLayout";
import { useSetPageName } from "../layouts/graphql";
import { BREADCRUMB_TYPES } from "../layouts/UserLayout/Breadcrumbs/utils";
import { useFirebase } from "./firebase";

export const routePropTypes = {
  path: PropTypes.string.isRequired,
  uri: PropTypes.string,
  location: PropTypes.object,
  navigate: PropTypes.func
};

const Route = props => {
  const {
    path,
    uri,
    location,
    navigate,
    layout: Layout,
    layoutProps,
    component: Component,
    pageName,
    home,
    breadcrumb,
    breadcrumbType,
    children,
    isProtected,
    ...componentProps
  } = props;

  const { isAuthenticated } = useFirebase();
  const addBreadcrumb = useAddBreadcrumb();
  const clearBreadcrumbs = useClearBreadcrumbs();
  const setPageName = useSetPageName();

  if (!isAuthenticated && isProtected) {
    navigate("/login");
    return null;
  }

  if (pageName) {
    setPageName(pageName);
  }

  // root route, clear breadcrumbs
  if (home) {
    clearBreadcrumbs();
  }

  // route path should be an item in breadcrumbs
  if (breadcrumb || breadcrumbType) {
    addBreadcrumb({
      url: uri,
      title: breadcrumb,
      type: breadcrumbType || BREADCRUMB_TYPES.DEFAULT
    });
  }

  // decorator (wrapper) route
  if (!Component) {
    return children;
  }

  const routeProps = { path, uri, navigate };

  return (
    <Layout {...routeProps} {...layoutProps}>
      <Component {...routeProps} {...componentProps} />
    </Layout>
  );
};

Route.propTypes = {
  layout: PropTypes.elementType,
  layoutProps: PropTypes.object,
  component: PropTypes.elementType,
  pageName: PropTypes.string,
  breadcrumb: PropTypes.string,
  breadcrumbType: PropTypes.string,
  home: PropTypes.bool,
  isProtected: PropTypes.bool,
  ...routePropTypes
};

Route.defaultProps = {
  layout: UserLayout,
  layoutProps: {},
  component: null,
  pageName: null,
  breadcrumb: null,
  breadcrumbType: null,
  home: false,
  isProtected: true
};

export default Route;
