import React from "react";
import PropTypes from "prop-types";
import { TYPE_MEDIA_TYPE } from "../../../../common/types";
import MediaForm, { FORM_DEFAULT_VALUES } from "./MediaForm";
import { useUploadFile } from "../graphql";
import handleSubmit from "./handleSubmit";

const MediaFormContainer = props => {
  const { onSubmit, ...rest } = props;

  const uploadFile = useUploadFile();

  return (
    <MediaForm onSubmit={handleSubmit({ onSubmit, uploadFile })} {...rest} />
  );
};

MediaFormContainer.propTypes = {
  mediaType: TYPE_MEDIA_TYPE,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({
    file: PropTypes.shape({}),
    src: PropTypes.string,
    caption: PropTypes.string,
    align: PropTypes.oneOf(["left", "center", "right"])
  }),
  showUpload: PropTypes.bool,
  showCaption: PropTypes.bool
};

MediaFormContainer.defaultProps = {
  showUpload: false,
  showCaption: true,
  defaultValues: FORM_DEFAULT_VALUES
};

export default MediaFormContainer;
