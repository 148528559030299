import gql from "graphql-tag";
import {useApolloClient, useQuery} from "@apollo/react-hooks";

export const GET_MODALS = gql`
  query GetModals {
    modals @client {
      modalId
      open
    }
  }
`;

export const useOpenModal = () => {
  const client = useApolloClient();

  return (modalId, open) => {
    const { modals } = client.readQuery({ query: GET_MODALS });

    const updatedModals = modals.map(modal => ({
      ...modal,
      open: modal.modalId === modalId ? open : modal.open
    }));

    client.writeData({ data: { modals: updatedModals } });
  };
};

export const useModal = (modalId, open = false) => {
  const client = useApolloClient();
  const {
    data: { modals }
  } = useQuery(GET_MODALS);

  const currentModal = modals.find(modal => modal.modalId === modalId);

  // new modal
  if (!currentModal) {
    const newModal = {
      modalId,
      open,
      __typename: "Modal"
    };
    const data = {
      modals: [...modals, newModal]
    };
    client.writeData({ data });
  }

  return {
    open: currentModal ? currentModal.open : open,
    //props: currentModal ? currentModal.props : {}
  }
};
