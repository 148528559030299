import React from "react";
import CourseList from "./course/CourseList";
import CourseDetails from "./course/CourseDetails";
import HomePage from "../pages/HomePage";
import Roles from "./user/Roles";
import CustomTheme from "./theme/CustomTheme";
import ProjectTheme from "./theme/ProjectTheme";
import { Router } from "@reach/router";
import Route from "./Route";
import { PAGE_NAMES } from "../layouts/util";
import LessonAssessments from "./lesson/LessonAssessments";
import LessonAssets from "./lesson/LessonAssets";
import { BREADCRUMB_TYPES } from "../layouts/UserLayout/Breadcrumbs/utils";
import ThemeList from "./theme/ThemeList";
import LessonEditor from "./lesson/LessonEditor";
import * as ROUTES from "./routes";
import LoginPage from "../pages/LoginPage";
import DefaultLayout from "../layouts/DefaultLayout";
import UsersPage from "../pages/UsersPage";

const AppRouter = () => (
  <Router>
    <Route path={ROUTES.HOME} home>
      <Route component={HomePage} path={ROUTES.HOME} pageName={PAGE_NAMES.HOME} />
      <Route path={ROUTES.COURSES} breadcrumb="Courses">
        <Route
          path=":courseId"
          breadcrumbType={BREADCRUMB_TYPES.COURSE}
          pageName={PAGE_NAMES.COURSE}
        >
          <Route component={CourseDetails} path="/" />
          <Route component={CourseDetails} path="syllabus" />
          <Route component={CourseDetails} path="settings" />
          <Route component={CourseDetails} path="activity" />
          <Route path=":sectionId" breadcrumbType={BREADCRUMB_TYPES.SECTION}>
            <Route path=":lessonId" pageName={PAGE_NAMES.LESSON}>
              <Route component={LessonEditor} path="content" />
              <Route component={LessonAssets} path="assets" />
              <Route component={LessonAssessments} path="assessments" />
            </Route>
          </Route>
        </Route>
        <Route component={CourseList} path="/" pageName={PAGE_NAMES.COURSES} />
      </Route>
      <Route
        path={ROUTES.THEMES}
        breadcrumb="Themes"
        pageName={PAGE_NAMES.THEME}
      >
        <Route
          path=":themeId"
          breadcrumbType={BREADCRUMB_TYPES.THEME}
          component={CustomTheme}
        />
        <Route component={ThemeList} path="/" />
      </Route>
      <Route
        path={ROUTES.PROJECT}
        pageName={PAGE_NAMES.THEME}
        breadcrumb="Project"
        component={ProjectTheme}
      />
      <Route path={ROUTES.USERS} pageName={PAGE_NAMES.USERS}>
        <Route breadcrumb="Roles" component={Roles} path="roles" />
        <Route breadcrumb="Users" component={UsersPage} path="/" />
      </Route>
    </Route>
    <Route
      isProtected={false}
      path={ROUTES.LOGIN}
      component={LoginPage}
      layout={DefaultLayout}
    />
  </Router>
);

export default AppRouter;
