import React, { useState } from "react";
import LessonEditorModal from "../LessonEditorModal";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import MediaForm from "../MediaForm";
import { useLessonEditor } from "../LessonEditorContext";
import onClose from "./onClose";
import onSave from "./onSave";
import { useInsertCustomBlock } from "../hooks";
import { MEDIA_TYPE } from "../../../../common/constants";

const MediaFormModal = props => {
  const {
    setReadOnly,
    focus,
    closeMediaForm,
    mediaModalProps
  } = useLessonEditor();
  const { mediaType, defaultValues, ...modalProps } = mediaModalProps;
  const showCaption = mediaType === MEDIA_TYPE.IMAGE;

  const insertNewBlock = useInsertCustomBlock();

  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, value) => setSelectedTab(value);

  const handleOnClose = onClose({
    closeModal: closeMediaForm,
    setReadOnly,
    focus
  });
  const handleOnSave = onSave({ mediaType, insertNewBlock, onClose: handleOnClose });

  return (
    <LessonEditorModal onClose={handleOnClose} {...props} {...modalProps}>
      <AppBar position="static" color="default">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="From Internet" />
          <Tab label="From computer" />
          <Tab label="From gallery" />
        </Tabs>
      </AppBar>
      {selectedTab === 0 && (
        <MediaForm
          onSubmit={handleOnSave}
          onCancel={handleOnClose}
          showCaption={showCaption}
          defaultValues={defaultValues}
          mediaType={mediaType}
        />
      )}
      {selectedTab === 1 && (
        <MediaForm
          onSubmit={handleOnSave}
          onCancel={handleOnClose}
          showCaption={showCaption}
          defaultValues={defaultValues}
          mediaType={mediaType}
          showUpload
        />
      )}
      {selectedTab === 2 && <div>TBD gallery</div>}
    </LessonEditorModal>
  );
};

export default MediaFormModal;
