import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import styled from "@material-ui/styles/styled";

export { TableRow, TableCell, Checkbox };

export const CheckboxCell = styled(TableCell)(() => ({
  width: "30px"
}));
