import {MEDIA_TYPE} from "../../../../common/constants";
import {useState} from "react";
import {FORM_DEFAULT_VALUES} from "../MediaForm";

const useMediaModal = setReadOnly => {
  const [modalProps, setModalProps] = useState({ open: false });
  return {
    openVideoForm: (defaultValues = FORM_DEFAULT_VALUES) => {
      setReadOnly(true);
      setModalProps({
        open: true,
        mediaType: MEDIA_TYPE.VIDEO,
        label: "Choose video",
        defaultValues
      });
    },
    openImageForm: (defaultValues = FORM_DEFAULT_VALUES) => {
      setReadOnly(true);
      setModalProps({
        open: true,
        mediaType: MEDIA_TYPE.IMAGE,
        label: "Choose image",
        defaultValues
      });
    },
    closeMediaForm: () => setModalProps({ open: false }),
    mediaModalProps: modalProps
  };
};

export default useMediaModal;
