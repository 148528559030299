import { BLOCK_TYPES } from "../constants";
import {
  LessonEditorBlockAtomic,
  LessonEditorBlockImage,
  LessonEditorBlockVideo
} from "../blocks";

const getBlockRendererFn = options => contentBlock => {
  const { addAsset, removeAsset, editorState, setEditorState } = options;

  const type = contentBlock.getType();

  switch (type) {
    case BLOCK_TYPES.IMAGE:
      return {
        component: LessonEditorBlockImage,
        props: {
          editorState,
          setEditorState,
          addAsset,
          removeAsset
        },
        editable: true
      };
    case BLOCK_TYPES.VIDEO:
      return {
        component: LessonEditorBlockVideo,
        editable: true,
        props: {
          addAsset,
          removeAsset
        }
      };
    case BLOCK_TYPES.ATOMIC:
      return {
        component: LessonEditorBlockAtomic,
        editable: false
      };
    default:
      return null;
  }
};

export default getBlockRendererFn;
