import React from "react";
import {FormatItalic} from "@material-ui/icons";
import ActionButton from "./ActionButton";
import {useToggleInlineStyle} from "../hooks";

export default function Italic() {
  const toggleInlineStyle = useToggleInlineStyle();
  return (
    <ActionButton
      label={"Italic"}
      action={() => toggleInlineStyle("ITALIC")}
      icon={FormatItalic}
    />
  );
}
