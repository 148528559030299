import React from "react";
import { useFirebase } from "../../firebase";
import { ROOT_URL } from "../../../common/constants";

const Image = (props, ref) => {
  const { src, alt, ...rest } = props;

  const firebase = useFirebase();

  // images that are stored on Firestore has src that starts with asset/
  const imageUrl = src.startsWith("asset/")
    ? `${ROOT_URL}/${src}?token=${firebase.getToken()}`
    : src;

  return <img src={imageUrl} ref={ref} alt={alt} {...rest} />;
};

export default React.forwardRef(Image);
