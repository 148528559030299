import React from "react";
import PropTypes from "prop-types";
import SectionList from "../../section/SectionList";
import SectionForm from "../../section/SectionForm";
import SectionConfirmDeleteDialog from "../../section/SectionConfirmDeleteDialog";
import LessonForm from "../../lesson/LessonForm/LessonFormContainer";
import LessonConfirmDeleteDialog from "../../lesson/LessonConfirmDeleteDialog/LessonConfirmDeleteDialogContainer";
import Typography from "@material-ui/core/Typography";
import {Paper, Wrapper} from "./styledComponents";

const CourseSyllabus = ({ course }) => {
  const { id: courseId } = course;

  return (
    <Wrapper>
      <Paper>
        <Typography gutterBottom variant="h5">
          {course.title}
        </Typography>
        <Typography component="p">{course.description}</Typography>
      </Paper>
      <SectionList sections={course.sections} courseId={courseId} />
      <SectionForm />
      <SectionConfirmDeleteDialog />
      <LessonForm />
      <LessonConfirmDeleteDialog />
    </Wrapper>
  );
};

CourseSyllabus.propTypes = {
  course: PropTypes.shape({}).isRequired
};

export default CourseSyllabus;
