import { DEFAULT_COURSE_STYLES } from "../../../hooks/useBuildCourseTheme";

export const THEME_INITIAL_VALUES = {
  headerLeft: "",
  headerRight: "",
  footerLeft: "",
  footerRight: "",
  styles: DEFAULT_COURSE_STYLES
};

export const buildThemeFormValues = theme => {
  // flatten object because useForm does not support nested object
  // TODO support nested object in form (using proxy, like e.g. Immer)
  const {
    id,
    name,
    headerLeft,
    headerRight,
    footerLeft,
    footerRight,
    styles: styleMap
  } = theme;

  const styles = { ...THEME_INITIAL_VALUES.styles, ...styleMap };
  delete styles.__typename;

  const initialValues = {
    id,
    name,
    headerLeft: headerLeft || THEME_INITIAL_VALUES.headerLeft,
    headerRight: headerRight || THEME_INITIAL_VALUES.headerRight,
    footerLeft: footerLeft || THEME_INITIAL_VALUES.footerLeft,
    footerRight: footerRight || THEME_INITIAL_VALUES.footerRight,
    ...styles
  };

  return initialValues;
};

export const buildThemeContent = formValues => {
  const {
    id,
    name,
    headerLeft,
    headerRight,
    footerLeft,
    footerRight,
    ...styles
  } = formValues;
  return { id, headerLeft, headerRight, footerLeft, footerRight, styles };
};
