import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";

export const defaultThemeDetailsFragment = gql`
  fragment defaultThemeDetails on DefaultTheme {
    headerLeft
    headerRight
    footerLeft
    footerRight
    styles {
      headerFont
      bodyFont
      quoteFont
      colorPrimary
      colorSecondary
    }
  }
`;

const GET_DEFAULT_THEME = gql`
  query getDefaultTheme {
    defaultTheme {
      ...defaultThemeDetails
    }
  }
  ${defaultThemeDetailsFragment}
`;

export const useGetDefaultTheme = () => {
  const { data, loading, error } = useQuery(GET_DEFAULT_THEME);

  return { data: data ? data.defaultTheme : data, loading, error };
};

export const UPDATE_DEFAULT_THEME = gql`
  mutation UpdateDefaultTheme($defaultTheme: DefaultThemeInput!) {
    updateDefaultTheme(defaultTheme: $defaultTheme) {
      ...defaultThemeDetails
    }
  }
  ${defaultThemeDetailsFragment}
`;

export const useUpdateDefaultTheme = () => {
  const [updateDefaultTheme] = useMutation(UPDATE_DEFAULT_THEME);

  return async defaultTheme =>
    await updateDefaultTheme({
      variables: { defaultTheme },
      refetchQueries: [{ query: GET_DEFAULT_THEME }]
    });
};
