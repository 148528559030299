import {DefaultDraftBlockRenderMap} from "draft-js";
import {Map} from "immutable";

const getBlockRenderMap = () => {
  DefaultDraftBlockRenderMap.merge(
    Map({
      // [BLOCK_TYPES.IMAGE]: {
      //   element: "img"
      // }
    })
  );
};

export default getBlockRenderMap;
