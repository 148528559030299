import PaperMUI from "@material-ui/core/Paper";
import TableMUI from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import styled from "@material-ui/styles/styled";

export {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination
};

export const Paper = styled(PaperMUI)(({ theme }) => ({
  flexShrink: 0,
  color: theme.palette.text.secondary,
  marginLeft: theme.spacing(2.5)
}));

export const Table = styled(TableMUI)(({ theme }) => ({
  marginTop: theme.spacing(3)
}));
