import React, { useState } from "react";
import { VIRTUAL_NODE } from "../constants";
import { useLessonEditor } from "../LessonEditorContext";
import LessonEditorPopup from "../LessonEditorPopup";
import SidebarActions from "./SidebarActions";
import getCurrentBlockNode from "./getCurrentBlockNode";

const LessonEditorSidebar = () => {
  const [contentBlockEl, setContentBlockEl] = useState(VIRTUAL_NODE);
  const { editorState } = useLessonEditor();

  // TODO convert to useEffect
  getCurrentBlockNode(editorState).then(node => setContentBlockEl(node));

  return (
    <LessonEditorPopup
      component={SidebarActions}
      referenceElement={contentBlockEl}
      placement="left-start"
      show={Boolean(contentBlockEl)}
    />
  );
};

export default LessonEditorSidebar;
