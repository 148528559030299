import { Link as LinkBase } from "@reach/router";
import { styled } from "@material-ui/styles";
import { Card as CardMUI } from "@material-ui/core";

export const Link = styled(LinkBase)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.primary.main
}));

export const ListItem = styled(CardMUI)(({ theme }) => ({
  marginBottom: theme.spacing(1)
}));
