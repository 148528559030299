import React from "react";
import PropTypes from "prop-types";
import {Grid} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/styles";
import LessonListItem from "./LessonListIem";

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "column"
  },
  addItem: {
    marginTop: theme.spacing(3),
    marginLeft: "auto"
  }
}));

const LessonList = ({
  openForm,
  confirmDelete,
  lessons
}) => {
  const classes = useStyles();

  const items = lessons.map(lesson => (
    <LessonListItem
      key={lesson.id}
      lesson={lesson}
      openForm={openForm}
      confirmDelete={confirmDelete}
    />
  ));

  return (
    <div className={classes.wrapper}>
      {items}
      <Grid item>
        <Divider light className={classes.divider} />
        <Grid container>
          <Button
            color="primary"
            className={classes.addItem}
            onClick={() => openForm()}
          >
            Add lesson
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

LessonList.propTypes = {
  openForm: PropTypes.func.isRequired,
  confirmDelete: PropTypes.func.isRequired,
  lessons: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default LessonList;
