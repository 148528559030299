import gql from "graphql-tag";
import {useApolloClient, useQuery} from "@apollo/react-hooks";
import {courseDetailsFragment} from "../graphql";

export const GET_COURSES = gql`
  query GetCourses {
    courses {
      ...courseDetails
    }
  }
  ${courseDetailsFragment}
`;

export const useGetCourses = () => {
  const client = useApolloClient();
  const { data, loading, error } = useQuery(GET_COURSES);

  // clear selected course
  if (!loading && data) {
    client.writeData({
      data: {
        selectedCourseId: null,
        selectedSectionId: null,
        selectedLessonId: null
      }
    });
  }

  return { data: data ? data.courses : data, loading, error };
};

