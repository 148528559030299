import React from "react";
import {FormatUnderlined} from "@material-ui/icons";
import ActionButton from "./ActionButton";
import {useToggleInlineStyle} from "../hooks";

export default function Underline() {
  const toggleInlineStyle = useToggleInlineStyle();
  return (
    <ActionButton
      label={"Underline"}
      action={() => toggleInlineStyle("UNDERLINE")}
      icon={FormatUnderlined}
    />
  );
}
