import styled from "@material-ui/core/styles/styled";
import IconButton from "@material-ui/core/IconButton/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

export {
  IconButton,
  FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPageIcon
};

export const Wrapper = styled("div")(({ theme }) => {
  const styles = {
    display: "flex",
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
    flexDirection: "row",
    justifyContent: "center"
  };

  if (theme.direction === "rtl") {
    styles.flexDirection = "row-reverse";
  }

  return styles;
});
