import React from "react";
import PropTypes from "prop-types";
import SubHeader from "../index";
import {useGetPageName} from "../../../graphql";
import {PAGE_NAMES} from "../../../util";

export const tabs = [
  { label: "Users", url: "/users" },
  { label: "Roles", url: "/users/roles" }
];

const UsersSubHeaderContainer = ({ uri }) => {
  // determine current page
  const { data } = useGetPageName();

  if (![PAGE_NAMES.USERS, PAGE_NAMES.ROLES].includes(data)) {
    return null;
  }

  return <SubHeader uri={uri} tabs={tabs} />;
};

UsersSubHeaderContainer.propTypes = {
  uri: PropTypes.string.isRequired
};

export default UsersSubHeaderContainer;
