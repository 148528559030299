import React from "react";
import {Grid, IconButton, Paper, Typography} from "@material-ui/core";
import ArrowForwardRounded from "@material-ui/icons/ArrowForwardRounded";
import ArrowBackRounded from "@material-ui/icons/ArrowBackRounded";
import {useBuildCourseTheme} from "../../../hooks/useBuildCourseTheme";

const ThemePreview = props => {
  const { theme } = props;

  const themeClasses = useBuildCourseTheme(theme);

  return (
    <div className={themeClasses.root}>
      <Typography variant="h6" gutterBottom>
        Preview
      </Typography>
      <Paper>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h6" className={themeClasses.header}>
              Header
            </Typography>
          </Grid>
          <Grid className={themeClasses.main}>
            <Typography variant="h6">Heading Fonts Get Noticed</Typography>
            <Typography component="p" className={themeClasses.p}>
              This is the body font. The bulk of what we read is body copy. It's
              the novels, magazine articles, newspaper stories, contracts, and
              Web pages we read day after day. Text fonts are the typefaces used
              for body copy. Body copy requires legible, easy to read text
              fonts. Here are tips on how to choose your fonts.
            </Typography>
            <Typography component="blockquote" className={themeClasses.quote}>
              This a beautiful quote that will inspire you.
            </Typography>
            <Typography component="p" className={themeClasses.p}>
              Body fonts are usually used with longer text, like a paragraph or
              secondary text. We recommend an easy-to-read font that pairs well
              with your heading font. You may want to choose an easy to read
              font that compliments your heading font choice. Body fonts also
              support bold and italics styles. If you’d like you can select your
              regular font, and we will build the italics and bold styles
              automatically for you, but your text will look its best when you
              also upload the font files all the text styling options.
            </Typography>
          </Grid>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={themeClasses.footer}
          >
            <Grid item lg={3}>
              <Typography component={"span"}>{theme.footerLeft}</Typography>
            </Grid>
            <Grid item lg={6} className={themeClasses.middleFooter}>
              <IconButton color="inherit">
                <ArrowBackRounded />
              </IconButton>
              <Typography component={"span"} className={themeClasses.lessonName}>
                Next Lesson
              </Typography>
              <IconButton color="inherit">
                <ArrowForwardRounded />
              </IconButton>
            </Grid>
            <Grid item lg={3} className={themeClasses.rightFooter}>
              <Typography component={"span"}>{theme.footerRight}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default ThemePreview;
