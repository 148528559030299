import gql from "graphql-tag";
import {themeDetailsFragment} from "../graphql";
import {useApolloClient, useQuery} from "@apollo/react-hooks";

export const GET_THEMES = gql`
  query GetThemes {
    themes {
      ...themeDetails
    }
  }
  ${themeDetailsFragment}
`;

export const useGetThemes = () => {
  const client = useApolloClient();
  const { data, loading, error } = useQuery(GET_THEMES);

  if (!loading && data) {
    client.writeData({
      data: { selectedThemeId: null }
    });
  }

  return { data: data ? data.themes : data, loading, error };
};
