import React from "react";
import ThemePreview from "../ThemePreview";
import { Grid } from "@material-ui/core";
import { TYPE_THEME } from "../../../common/types";
import useForm from "../../../hooks/useForm";
import PropTypes from "prop-types";
import ThemeEditorForm from "../ThemeEditorForm";
import { preventDefault } from "../../../common/util";
import { Wrapper, Button } from "./styledComponents";

//TODO
// * Get fonts from directory
// * Upload fonts
const ThemeEditor = props => {
  const { defaultValues, onSubmit } = props;

  const form = useForm({
    defaultValues,
    submitHandler: onSubmit
  });

  const handleSubmit = preventDefault(form.submit);

  return (
    <Wrapper>
      <Grid container>
        <Grid item xs={12} md={3}>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={handleSubmit}
            >
              Save
            </Button>
            <ThemeEditorForm form={form} />
          </form>
        </Grid>
        <Grid item xs={12} md={9}>
          <ThemePreview theme={form.formValues} />
        </Grid>
      </Grid>
    </Wrapper>
  );
};

ThemeEditor.propTypes = {
  defaultValues: TYPE_THEME.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ThemeEditor;
