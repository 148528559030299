import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {GET_COURSE} from "../../course/CourseDetails/graphql";

export const DELETE_SECTION = gql`
  mutation DeleteSection($courseId: ID!, $sectionId: ID!) {
    deleteSection(courseId: $courseId, sectionId: $sectionId)
  }
`;

export const useDeleteSectionMutation = () => {
  const [deleteSection] = useMutation(DELETE_SECTION);

  return async (courseId, sectionId) => {
    await deleteSection({
      variables: { courseId, sectionId },
      update: (proxy, { data: { deleteSection: deletedSectionId } }) => {
        const { course } = proxy.readQuery({
          query: GET_COURSE,
          variables: { id: courseId }
        });
        const updatedSections = course.sections.filter(
          ({ id }) => id !== deletedSectionId
        );
        proxy.writeQuery({
          query: GET_COURSE,
          variables: { id: courseId },
          data: { course: { ...course, sections: updatedSections } }
        });
        proxy.writeData({
          data: {
            selectedSectionId: null
          }
        });
      }
    });
  };
};
