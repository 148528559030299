import React from "react";
import PropTypes from "prop-types";
import { TYPE_USERS } from "../../../common/types";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow
} from "./styledComponents";
import UserListItem from "./UserListItem";
import Pagination from "../../shared/Pagination";

const UserList = props => {
  const { users, onSelect, onDeselect, onRoleRemove, selectedUsers } = props;

  const userRows = users.map((user, index) => (
    <UserListItem
      key={index}
      onRoleRemove={onRoleRemove}
      user={user}
      onSelect={onSelect}
      onDeselect={onDeselect}
      selected={selectedUsers.includes(user)}
    />
  ));

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Select</TableCell>
            <TableCell>User</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Roles</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{userRows}</TableBody>
        <TableFooter>
          <TableRow>
            <Pagination
              colSpan={4}
              count={userRows.length}
              onChange={Function.prototype}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
};

UserList.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
  onRoleRemove: PropTypes.func.isRequired,
  users: TYPE_USERS.isRequired,
  selectedUsers: TYPE_USERS.isRequired
};

export default UserList;
