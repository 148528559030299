import React, {useMemo} from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core";
import useForm from "../../../hooks/useForm";
import {buildFormValues, validateThemeForm} from "./utils";
import {preventDefault} from "../../../common/util";
import Modal from "../../shared/Modal";
import {TextField} from "../../shared/FormFields";
import Overlay from "../../shared/Overlay";
import {TYPE_THEME} from "../../../common/types";

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  }
}));

const ThemeForm = props => {
  const {
    modalId,
    modalTitle,
    onSubmit,
    onCancel,
    submitButtonLabel,
    cancelButtonLabel,
    theme
  } = props;

  const classes = useStyles();

  const defaultValues = useMemo(() => buildFormValues(theme), [theme]);

  const form = useForm({
    defaultValues,
    submitHandler: onSubmit,
    validatorHandler: validateThemeForm,
    reinitialize: !Boolean(defaultValues.id)
  });

  const handleSubmit = preventDefault(form.submit);

  const handleCancel = preventDefault(() => {
    if (form.isSubmitting) {
      return;
    }
    onCancel();
    form.reset();
  });

  return (
    <Modal
      modalId={modalId}
      title={modalTitle}
      onConfirm={handleSubmit}
      onCancel={handleCancel}
      confirmButtonLabel={submitButtonLabel}
      cancelButtonLabel={cancelButtonLabel}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit} className={classes.form}>
        <TextField label="Name" {...form.field("name")} autoFocus />
        <TextField label="Description" {...form.field("description")} />
      </form>
      {form.isSubmitting && <Overlay />}
    </Modal>
  );
};

ThemeForm.propTypes = {
  modalId: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  theme: TYPE_THEME
};

export default ThemeForm;
