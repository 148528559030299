import {genKey} from "draft-js";

export const buildTranscript = () => {
  return `{"blocks":[{"key":"${genKey()}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;
};

export const NEW_LESSON = {
  id: null,
  courseId: null,
  sectionId: null,
  title: "",
  description: "",
  transcript: null,
  position: 0
};

export const buildFormValues = (defaultValues = {}) => {
  // TODO find batter way of finding objects intersection
  const {
    id,
    courseId,
    sectionId,
    title,
    description,
    transcript,
    position
  } = { ...NEW_LESSON, ...defaultValues };
  return {
    id,
    courseId,
    sectionId,
    title,
    description,
    transcript,
    position
  };
};

export const validateLessonForm = (values = {}) => {
  const errors = {};
  if (!values || !values.title || values.title.trim().length === 0) {
    errors.title = "Title must be set";
  }
  return errors;
};
