import gql from "graphql-tag";
import {useApolloClient, useQuery} from "@apollo/react-hooks";

export const GET_PAGE_NAME = gql`
  query GetPageName {
    pageName @client
  }
`;

export const useGetPageName = () => {
  const { data, loading, error } = useQuery(GET_PAGE_NAME);

  return { data: data ? data.pageName : data, loading, error };
};

export const useSetPageName = () => {
  const client = useApolloClient();

  return pageName => {
    client.writeData({ data: { pageName } });
    return pageName;
  };
};
