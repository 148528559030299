import { Dialog, DialogTitle } from "./styledComponents";
import React from "react";
import PropTypes from "prop-types";

export default function LessonEditorModal(props) {
  const { label, children, ...rest } = props;

  return (
    <Dialog {...rest}>
      <DialogTitle>{label}</DialogTitle>
      {children}
    </Dialog>
  );
}

LessonEditorModal.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node.isRequired
};

LessonEditorModal.defaultProps = {
  label: ""
};
