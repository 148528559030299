import {useLessonEditor} from "../LessonEditorContext";
import {RichUtils} from "draft-js";
import {collapseSelection} from "../helpers";

const useToggleBlockType = () => {
  const { editorState, setEditorState } = useLessonEditor();

  return newBlockType => {
    setEditorState(
      RichUtils.toggleBlockType(editorState, newBlockType),
      collapseSelection(editorState)
    );
  };
};

export default useToggleBlockType;
