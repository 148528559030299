import React, { useRef } from "react";
import { Popper } from "@material-ui/core";
import { Wrapper } from "./styledComponents";
import PropTypes from "prop-types";
import get from "lodash.get";

export const modifiers = {
  flip: {
    enabled: true
  }
};

const LessonEditorPopup = props => {
  const {
    component: Component,
    componentProps,
    show,
    referenceElement,
    classes,
    ...rest
  } = props;

  const popperRef = useRef(null);

  return (
    <Popper
      open={show}
      disablePortal={true}
      anchorEl={referenceElement}
      transition
      modifiers={modifiers}
      popperRef={popperRef}
      {...rest}
    >
      {options => {
        const { placement } = options;

        const scheduleUpdate = get(
          popperRef,
          "current.scheduleUpdate",
          Function.prototype
        );

        scheduleUpdate();

        return (
          <Wrapper classes={classes} data-popper-placement={placement}>
            <Component {...componentProps} />
          </Wrapper>
        );
      }}
    </Popper>
  );
};

LessonEditorPopup.propTypes = {
  referenceElement: PropTypes.object.isRequired,
  show: PropTypes.bool,
  component: PropTypes.func.isRequired,
  componentProps: PropTypes.object,
  classes: PropTypes.string
};

LessonEditorPopup.defaultProps = {
  show: false,
  componentProps: {},
  classes: null
};

export default LessonEditorPopup;
