import React from "react";
import PropTypes from "prop-types";
import {
  useGetTheme,
  useUpdateThemeContentMutation
} from "./graphql";
import Spinner from "../../shared/Spinner";
import { navigate } from "@reach/router";
import ThemeEditor from "../ThemeEditor";

const CustomThemeContainer = props => {
  const { themeId } = props;

  const updateThemeContent = useUpdateThemeContentMutation();
  const { data: theme, loading, error } = useGetTheme(themeId);

  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return `Error! ${error.message}`;
  }

  const onSave = async themeContent => {
    await updateThemeContent(themeContent);
    navigate(`/themes`);
  };

  return <ThemeEditor theme={theme} onSave={onSave} />;
};

CustomThemeContainer.propTypes = {
  themeId: PropTypes.string.isRequired
};

export default CustomThemeContainer;
