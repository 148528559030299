import React from "react";
import { LoginPanel, Wrapper, GoogleIcon } from "./styledComponents";
import { useFirebase } from "../../components/firebase";
import { navigate } from "@reach/router";
import BatteryIcon from "../../icons/BatteryIcon";
import { Button } from "@material-ui/core";

const LoginPage = () => {
  const { currentUser, login } = useFirebase();

  if (currentUser) {
    navigate("/");
    return null;
  }

  return (
    <Wrapper>
      <LoginPanel>
        <BatteryIcon />
        <Button variant="contained" onClick={login}>
          <GoogleIcon />
          Sign in with Google
        </Button>
      </LoginPanel>
    </Wrapper>
  );
};

export default LoginPage;
