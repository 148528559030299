import {useCallback, useReducer} from "react";

const ASSETS_ACTIONS = {
  ADD: "ADD",
  REMOVE: "REMOVE"
};

export const addAsset = dispatch => url =>
  dispatch({ type: ASSETS_ACTIONS.ADD, payload: url });
export const removeAsset = dispatch => url =>
  dispatch({
    type: ASSETS_ACTIONS.REMOVE,
    payload: url
  });

export const assetsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case ASSETS_ACTIONS.ADD:
      return [...state, payload];
    case ASSETS_ACTIONS.REMOVE:
      return state.filter(url => url !== payload);
    default:
      return state;
  }
};

const useAssets = () => {
  const [assets, dispatch] = useReducer(assetsReducer, []);

  return {
    assets,
    addAsset: useCallback(addAsset(dispatch), []),
    removeAsset: useCallback(removeAsset(dispatch), [])
  };
};

export default useAssets;
