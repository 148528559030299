export const NEW_COURSE = {
  id: null,
  title: "",
  description: "",
  themeId: null
};

export const validateCourseForm = (values = {}) => {
  const errors = {};
  if (!values || !values.title || values.title.trim().length === 0) {
    errors.title = "Title must be set";
  }
  if (!values || !values.themeId) {
    errors.themeId = "Theme must be set";
  }
  return errors;
};

export const buildFormValues = (defaultValues = {}) => {
  // TODO find batter way of finding objects intersection
  const { id, title, description, themeId } = {
    ...NEW_COURSE,
    ...defaultValues
  };
  return { id, title, description, themeId };
};
