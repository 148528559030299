import React from "react";
import ActionButton from "./ActionButton";
import {Code} from "@material-ui/icons";
import {useToggleBlockType} from "../hooks";

export default function ConvertToTitle() {
  const toggleBlockType = useToggleBlockType();
  return (
    <ActionButton
      label={"Convert to code"}
      action={() => toggleBlockType("code-block")}
      icon={Code}
    />
  );
}
