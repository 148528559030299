import {useSelectThemeMutation} from "../graphql";
import {useGetThemes} from "./graphql";
import Spinner from "../../shared/Spinner";
import React from "react";
import ThemeList from "./ThemeList";
import {useOpenModal} from "../../shared/Modal/graphql";
import {MODALS} from "../../../common/constants";

const ThemeListContainer = () => {
  const selectTheme = useSelectThemeMutation();
  const openModal = useOpenModal();
  const { data, error, loading } = useGetThemes();

  const openForm = (theme = null) => {
    selectTheme(theme);
    openModal(MODALS.THEME_FORM_MODAL, true);
  };

  const confirmDelete = theme => {
    selectTheme(theme);
    openModal(MODALS.THEME_DELETE_MODAL, true);
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return `Error! ${error.message}`;
  }

  return (
    <ThemeList
      themes={data}
      openForm={openForm}
      confirmDelete={confirmDelete}
    />
  );
};

export default ThemeListContainer;
