import {insertCustomBlock} from "../hooks/useInsertCustomBlock";
import createNewBlock from "./createNewBlock";
import {BLOCK_TYPES} from "../constants";

const handleReturn = setEditorState => (event, editorState) => {
  const selection = editorState.getSelection();
  // selection is active
  if (selection.getAnchorKey() !== selection.getFocusKey()) {
    return "not-handled";
  }
  const content = editorState.getCurrentContent();
  const block = content.getBlockForKey(selection.getAnchorKey());

  if (BLOCK_TYPES.IMAGE === block.getType()) {
    insertCustomBlock(editorState, setEditorState)(createNewBlock());
    return "handled";
  }

  return "not-handled";
};

export default handleReturn;
