import React from "react";
import { Videocam } from "@material-ui/icons";
import ActionButton from "./ActionButton";
import { useLessonEditor } from "../LessonEditorContext";

const AddVideo = () => {
  const { openVideoForm } = useLessonEditor();

  return (
    <ActionButton label={"Add video"} action={openVideoForm} icon={Videocam} />
  );
};

export default AddVideo;
