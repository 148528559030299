import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {RadioField} from "../../shared/FormFields";
import {Wrapper} from "./styledComponents";
import {findRole} from "./findRole";
import {INSTRUCTIONAL_ROLES, ORGANIZATION_ROLES, VISUAL_ROLES} from "../../../common/constants";
import onRoleChange from "./onRoleChange";

const RolesPicker = props => {
  const { onChange, roles } = props;

  const [organizationRole, setOrganizationRole] = useState(
    findRole(roles, INSTRUCTIONAL_ROLES)
  );
  const [instructionalRole, setInstructionalRole] = useState(
    findRole(roles, ORGANIZATION_ROLES)
  );
  const [visualRole, setVisualRole] = useState(findRole(roles, VISUAL_ROLES));

  useEffect(() => {
    const newRoles = [organizationRole, instructionalRole, visualRole].filter(
      String
    );
    onChange(newRoles);
  }, [organizationRole, instructionalRole, visualRole, onChange]);

  return (
    <Wrapper>
      <RadioField
        label="Organization"
        name="organization"
        options={ORGANIZATION_ROLES}
        value={organizationRole}
        onChange={onRoleChange(setOrganizationRole)}
      />
      <RadioField
        label="Instructional"
        name="instructional"
        options={INSTRUCTIONAL_ROLES}
        value={instructionalRole}
        onChange={onRoleChange(setInstructionalRole)}
      />
      <RadioField
        label="Visual"
        name="visual"
        options={VISUAL_ROLES}
        value={visualRole}
        onChange={onRoleChange(setVisualRole)}
      />
    </Wrapper>
  );
};

RolesPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string)
};

RolesPicker.defaultProps = {
  roles: []
};

export default RolesPicker;
