import React from "react";
import PropTypes from "prop-types";
import { ActionButton } from "../../../actions";
import {
  Delete,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight
} from "@material-ui/icons";
import useModifyBlockData from "../../../hooks/useModifyBlockData";
import useRemoveBlock from "../../../hooks/useRemoveBlock";

const ImageInlineActions = props => {
  const { imageBlockKey } = props;

  const modifyBlockData = useModifyBlockData();
  const removeBlock = useRemoveBlock();

  const setAlign = align => modifyBlockData(imageBlockKey, { align });

  return (
    <>
      <ActionButton
        label={"Left"}
        action={() => {
          setAlign("left");
        }}
        icon={FormatAlignLeft}
      />
      <ActionButton
        label={"Center"}
        action={() => {
          setAlign("center");
        }}
        icon={FormatAlignCenter}
      />
      <ActionButton
        label={"Right"}
        action={() => {
          setAlign("right");
        }}
        icon={FormatAlignRight}
      />
      <ActionButton
        label={"Remove"}
        action={() => {
          removeBlock(imageBlockKey);
        }}
        icon={Delete}
      />
    </>
  );
};

ImageInlineActions.propTypes = {
  imageBlockKey: PropTypes.string.isRequired
};

export default ImageInlineActions;
