import {BlockMapBuilder, EditorState} from "draft-js";
import {useLessonEditor} from "../LessonEditorContext";
import createNewBlock from "../helpers/createNewBlock";

// TODO refactor this, move into helpers
export const insertCustomBlock = (editorState, setEditorState) => (
  newBlock,
  replace = false
) => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const targetKey = selectionState.getStartKey();

  const array = [];

  const blankBlock = createNewBlock();
  contentState.getBlockMap().forEach((block, blockKey) => {
    if (replace) {
      if (blockKey === targetKey) {
        array.push(newBlock);
        array.push(blankBlock);
      } else {
        array.push(block);
      }
    } else {
      array.push(block);
      if (blockKey !== targetKey) return;
      array.push(newBlock);
    }
  });

  const focusBlock = replace ? blankBlock : newBlock;

  const newContentState = contentState.merge({
    blockMap: BlockMapBuilder.createFromArray(array),
    selectionBefore: selectionState,
    selectionAfter: selectionState.merge({
      anchorKey: focusBlock.getKey(),
      anchorOffset: focusBlock.getLength(),
      focusKey: focusBlock.getKey(),
      focusOffset: focusBlock.getLength(),
      isBackward: false
    })
  });

  const newEditorState = EditorState.push(
    editorState,
    newContentState,
    "insert-fragment"
  );

  setEditorState(newEditorState);

  return newEditorState;
};

const useInsertCustomBlock = () => {
  const { editorState, setEditorState } = useLessonEditor();
  return insertCustomBlock(editorState, setEditorState);
};

export default useInsertCustomBlock;
