/**
 * This component is responsible for bringing image choose dialog
 * and inserting chosen image as block into editor.
 */
import React from "react";
import { AddAPhoto } from "@material-ui/icons";
import ActionButton from "./ActionButton";
import { useLessonEditor } from "../LessonEditorContext";

const AddImage = () => {
  const { openImageForm } = useLessonEditor();

  return (
    <ActionButton label={"Add image"} action={openImageForm} icon={AddAPhoto} />
  );
};

export default AddImage;
