import { makeStyles, styled } from "@material-ui/styles";
import {
  Box as BoxMUI,
  Button as ButtonMUI,
  Paper as PaperMUI
} from "@material-ui/core";

export const useEditorStyles = makeStyles(() => ({
  "@global": {
    'div.DraftEditor-root div[data-block="true"]': {
      marginBottom: "10px"
    },

    "div.DraftEditor-root .block": {
      maxWidth: "680px",
      marginLeft: "auto",
      marginRight: "auto"
    },

    "div.DraftEditor-root .public-DraftStyleDefault-block": {
      position: "static"
    },

    "div.DraftEditor-root div.block-media": {
      maxWidth: "100%",
      marginLeft: "inherit",
      marginRight: "inherit"
    },

    'div.DraftEditor-root div.block-image[data-block="true"].left': {
      float: "left",
      display: "inline-block",
      marginRight: "10px"
    },

    'div.DraftEditor-root div.block-image[data-block="true"].right': {
      float: "right",
      display: "inline-block",
      marginLeft: "10px"
    },

    'div.DraftEditor-root div[data-contents="true"]::after': {
      content: '""',
      clear: "both",
      display: "table"
    }
  }
}));

export const Wrapper = styled(BoxMUI)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(3)
}));

export const Actions = styled(BoxMUI)(({ theme }) => ({
  display: "flex",
  alignSelf: "flex-end",
  padding: theme.spacing(1)
}));

export const Button = styled(ButtonMUI)(({ theme }) => ({
  marginRight: theme.spacing(1)
}));

export const Paper = styled(PaperMUI)(({ theme }) => ({
  padding: `${theme.spacing(3)}px ${theme.spacing(9)}px`,
  position: "relative",
  minHeight: "300px"
}));

export const Header = styled(BoxMUI)({
  display: "flex",
  justifyContent: "space-between"
});

export const Footer = styled(BoxMUI)({
  display: "flex",
  justifyContent: "space-between"
});

export const Column = styled(BoxMUI)(() => ({}));
