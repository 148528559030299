import React, { useRef } from "react";
import { useCreateCourseMutation } from "./graphql";
import { useOpenModal } from "../../shared/Modal/graphql";
import { MODALS } from "../../../common/constants";
import { preventDefault } from "../../../common/util";
import Modal from "../../shared/Modal";
import CourseForm from "../CourseForm";

const modalId = MODALS.ADD_COURSE_FORM_MODAL;

const AddCourseFormContainer = () => {
  const createCourse = useCreateCourseMutation();
  const openModal = useOpenModal();
  const formRef = useRef(null);

  const onCancel = preventDefault(() => {
    if (formRef.current.isSubmitting) {
      return;
    }
    openModal(modalId, false);
    formRef.current.reset();
  });

  const onConfirm = preventDefault(async () => {
    const processed = await formRef.current.submit();
    if (processed) {
      openModal(modalId, false);
    }
  });

  return (
    <Modal
      modalId={modalId}
      title="Add new course"
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmButtonLabel="Add"
      maxWidth="md"
      fullWidth
    >
      <CourseForm onSubmit={createCourse} formRef={formRef} />
    </Modal>
  );
};

export default AddCourseFormContainer;
