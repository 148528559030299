import React from "react";
import PropTypes from "prop-types";
import SectionList from "./SectionList";
import {useOpenModal} from "../../shared/Modal/graphql";
import {MODALS} from "../../../common/constants";
import {useSelectSectionMutation} from "../graphql";

const SectionListContainer = ({ sections }) => {
  const selectSection = useSelectSectionMutation();
  const openModal = useOpenModal();

  const openForm = (section = null) => {
    selectSection(section);
    openModal(MODALS.SECTION_FORM_MODAL, true);
  };

  const confirmDelete = section => {
    selectSection(section);
    openModal(MODALS.SECTION_DELETE_MODAL, true);
  };

  return (
    <SectionList
      openForm={openForm}
      confirmDelete={confirmDelete}
      sections={sections}
    />
  );
};

SectionListContainer.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default SectionListContainer;
