import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {GET_COURSE} from "../../course/CourseDetails/graphql";

export const DELETE_LESSON = gql`
  mutation DeleteLesson($courseId: ID!, $sectionId: ID!, $lessonId: ID!) {
    deleteLesson(
      courseId: $courseId
      sectionId: $sectionId
      lessonId: $lessonId
    )
  }
`;

export const useDeleteLessonMutation = () => {
  const [deleteLesson] = useMutation(DELETE_LESSON);

  return async (courseId, sectionId, lessonId) => {
    await deleteLesson({
      variables: { courseId, sectionId, lessonId },
      update: (proxy, { data: { deleteLesson: deletedLessonId } }) => {
        const { course } = proxy.readQuery({
          query: GET_COURSE,
          variables: { id: courseId }
        });
        const [section] = course.sections.filter(({ id }) => id === sectionId);
        const updatedLessons = section.lessons.filter(
          ({ id }) => id !== deletedLessonId
        );
        section.lessons = updatedLessons;
        proxy.writeQuery({
          query: GET_COURSE,
          variables: { id: courseId },
          data: { course }
        });
        proxy.writeData({
          data: {
            selectedLessonId: null
          }
        });
      }
    });
  };
};
