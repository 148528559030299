import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { userDetailsFragment } from "../graphql";

const GET_USERS = gql`
  query GetUsers {
    users {
      id
      ...userDetails
    }
  }
  ${userDetailsFragment}
`;

export const useGetUsers = () => {
  const { data, loading, error } = useQuery(GET_USERS);

  return { data: data ? data.users : data, loading, error };
};

const REMOVE_USER_ROLE_MUTATION = gql`
  mutation removeUserRole($userRole: UserRoleInput!) {
    removeUserRole(userRole: $userRole) {
      id
      roles
    }
  }
`;

export const useRemoveUserRoleMutation = () => {
  const [removeUserRoleMutation] = useMutation(REMOVE_USER_ROLE_MUTATION);

  return async options => {
    const {
      user: { id },
      role
    } = options;

    return await removeUserRoleMutation({
      variables: { userRole: { id, role } }
    });
  };
};
