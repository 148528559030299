import React, { useState } from "react";
import PropTypes from "prop-types";
import { MEDIA_TYPE } from "../../../common/constants";
import { TYPE_MEDIA_TYPE } from "../../../common/types";
import { Image, Video, Wrapper } from "./styledComponents";
import onFileInputChange from "./onFileInputChange";

const FileUploader = props => {
  const { mediaType, onChange, ...rest } = props;

  const [file, setFile] = useState(null);

  const onChangeHandler = onFileInputChange({ setFile, onChange });

  const isImage = mediaType === MEDIA_TYPE.IMAGE && Boolean(file);
  const isVideo = mediaType === MEDIA_TYPE.VIDEO && Boolean(file);

  return (
    <Wrapper>
      <input
        type="file"
        onChange={onChangeHandler}
        accept={`${mediaType}/*`}
        {...rest}
      />
      {isImage && <Image src={file.preview} alt="Preview" />}
      {isVideo && <Video src={file.preview} controls />}
    </Wrapper>
  );
};

FileUploader.propTypes = {
  onChange: PropTypes.func.isRequired,
  mediaType: TYPE_MEDIA_TYPE.isRequired
};

export default FileUploader;
