import {CircularProgress} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  spinner: {
    margin: "auto",
    alignSelf: "center"
  },
  root: {
    display: "inline-flex",
    position: "relative",
    width: "100%",
    height: "100%",
    minHeight: "150px"
  },
  inline: {
    marginLeft: "10px"
  }
}));

// TODO this should be just styled spinner without width and height
const Spinner = props => {
  const { inline } = props;

  const classes = useStyles();

  if (inline) {
    return (
      <CircularProgress
        color="secondary"
        size="1rem"
        className={classes.inline}
      />
    );
  }

  return (
    <div className={classes.root}>
      <CircularProgress className={classes.spinner} color="primary" />
    </div>
  );
};
export default Spinner;
