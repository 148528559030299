import styled from "@material-ui/styles/styled";
import ImageBase from "../../../../shared/Image";
export const Wrapper = styled("div")({
  textAlign: "center"
});

export const Image = styled(ImageBase)(
  ({ "data-selected": isSelected, theme }) => {
    const styles = {
      cursor: "pointer",
      maxWidth: "100%"
    };

    if (isSelected) {
      styles.boxShadow = `0 0 0 3px ${theme.palette.primary.main}`;
    }

    return styles;
  }
);

export const Caption = styled("figcaption")({ display: "inline-block" });
