import DialogMUI from "@material-ui/core/Dialog";
import DialogTitleMUI from "@material-ui/core/DialogTitle";
import styled from "@material-ui/styles/styled";

export { DialogTitleMUI as DialogTitle };

export const Dialog = styled(DialogMUI)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1)
}));
