import PropTypes from "prop-types";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import React from "react";
import {useModal} from "./graphql";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative"
  }
}));

const Modal = props => {
  const {
    modalId,
    title,
    onConfirm,
    onCancel,
    confirmButtonLabel,
    cancelButtonLabel,
    children,
    ...dialogProps
  } = props;
  const classes = useStyles();
  const { open: show } = useModal(modalId, dialogProps.open);

  const hasActions = onConfirm || onCancel;

  const confirmCTA = onConfirm && (
    <Button onClick={onConfirm} color="primary">
      {confirmButtonLabel}
    </Button>
  );

  const cancelCTA = onCancel && (
    <Button onClick={onCancel} color="primary" autoFocus>
      {cancelButtonLabel}
    </Button>
  );

  return (
    <Dialog
      aria-labelledby={`modal-${title}`}
      {...dialogProps}
      open={show}
      onClose={onCancel}
      className={classes.wrapper}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      {hasActions && (
        <DialogActions>
          {confirmCTA}
          {cancelCTA}
        </DialogActions>
      )}
    </Dialog>
  );
};

Modal.propTypes = {
  modalId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string
};

Modal.defaultProps = {
  confirmButtonLabel: "Ok",
  cancelButtonLabel: "Cancel"
};

export default Modal;
