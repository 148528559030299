import ThemeEditorFonts from "../ThemeEditorFonts/ThemeEditorFonts";
import ThemeEditorColors from "../ThemeEditorColors";
import React from "react";
import {makeStyles} from "@material-ui/core";
import {TYPE_FORM} from "../../../common/types";
import {TextField} from "../../shared/FormFields";
import ThemeAccordion from "../ThemeAccordion";

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%"
  }
}));

const ThemeEditorForm = props => {
  const { form } = props;

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("");

  const handleChange = panelName => () => {
    setExpanded(expanded === panelName ? "" : panelName);
  };

  return (
    <>
      <ThemeAccordion
        panelName="fonts"
        title="Fonts"
        onChange={handleChange("fonts")}
        expanded={expanded === "fonts"}
      >
        <ThemeEditorFonts form={form} />
      </ThemeAccordion>
      <ThemeAccordion
        panelName="colors"
        title="Colors"
        onChange={handleChange("colors")}
        expanded={expanded === "colors"}
      >
        <ThemeEditorColors form={form} />
      </ThemeAccordion>
      <ThemeAccordion
        panelName="header"
        title="Header"
        onChange={handleChange("header")}
        expanded={expanded === "header"}
      >
        <TextField
          label="Left"
          multiline
          rows="4"
          className={classes.textField}
          {...form.field("headerLeft")}
        />
        <TextField
          label="Right"
          multiline
          rows="4"
          className={classes.textField}
          {...form.field("headerRight")}
        />
      </ThemeAccordion>
      <ThemeAccordion
        panelName="footer"
        title="Footer"
        onChange={handleChange("footer")}
        expanded={expanded === "footer"}
      >
        <TextField
          label="Left"
          multiline
          rows="4"
          className={classes.textField}
          {...form.field("footerLeft")}
        />
        <TextField
          label="Right"
          multiline
          rows="4"
          className={classes.textField}
          {...form.field("footerRight")}
        />
      </ThemeAccordion>
    </>
  );
};

ThemeEditorForm.propTypes = {
  form: TYPE_FORM.isRequired
};

export default ThemeEditorForm;
