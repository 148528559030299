import {makeStyles} from "@material-ui/core";
import {Link} from "@reach/router";
import React from "react";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  item: {
    display: "flex",
    color: "white",
    textDecoration: "none"
  }
}));

const BreadcrumbItem = ({ url, title }) => {
  const classes = useStyles();

  return (
    <Link color="inherit" to={url} className={classes.item}>
      {title}
    </Link>
  );
};

BreadcrumbItem.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string
};

BreadcrumbItem.defaultProps = {
  url: null
};

export default BreadcrumbItem;
