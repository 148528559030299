import { BLOCK_TYPES } from "../constants";

const getBlockStyleFn = courseClasses => contentBlock => {
  switch (contentBlock.getType()) {
    case BLOCK_TYPES.IMAGE:
      const align = contentBlock.getData().get("align");
      return `block block-media block-image${align ? " " + align : ""}`;
    case BLOCK_TYPES.VIDEO:
      return "block block-media";
    case BLOCK_TYPES.PARAGRAPH:
      return `block ${courseClasses.p}`;
    default:
      return "block";
  }
};

export default getBlockStyleFn;
