import { styled } from "@material-ui/styles";

export const Wrapper = styled("div")({
  backgroundColor: "#18202c",
  color: "#ffffff",
  borderRadius: "10px",
  position: "relative",
  background: "#18202c",

  "&[data-popper-placement=bottom]": {
    top: "12px !important"
  },
  "&[data-popper-placement=bottom]::before": {
    bottom: "100%",
    left: "50%",
    border: "solid transparent",
    content: "' '",
    height: 0,
    width: 0,
    position: "absolute",
    pointerEvents: "none",
    borderColor: "rgba(24, 32, 44, 0)",
    borderBottomColor: "#18202c",
    borderWidth: "12px",
    marginLeft: "-12px"
  },

  "&[data-popper-placement=top]": {
    bottom: "12px !important"
  },
  "&[data-popper-placement=top]::before": {
    top: "100%",
    left: "50%",
    border: "solid transparent",
    content: "' '",
    height: 0,
    width: 0,
    position: "absolute",
    pointerEvents: "none",
    borderColor: "rgba(24, 32, 44, 0)",
    borderTopColor: "#18202c",
    borderWidth: "12px",
    marginLeft: "-12px"
  },

  "&[data-popper-placement=left-start]": {
    marginRight: "10px"
  }
});
