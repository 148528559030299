const onClose = options => () => {
  const { closeModal, setReadOnly, focus } = options;

  closeModal(false);

  setTimeout(() => {
    setReadOnly(false);
    focus();
  }, 0);
};

export default onClose;
