import styled from "@material-ui/styles/styled";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "block",
  width: "100%",
  textAlign: "center"
}));

export const Image = styled("img")(({ theme }) => ({
  display: "block",
  margin: "10px auto",
  maxWidth: "256px",
  maxHeight: "256px"
}));

export const Video = styled("video")(({ theme }) => ({
  display: "block",
  margin: "10px auto",
  maxWidth: "256px",
  maxHeight: "256px"
}));