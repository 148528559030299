import React from "react";
import {makeStyles} from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {IconButton} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoveIcon from "@material-ui/icons/DragIndicator";
import {Link} from "@reach/router";

const useStyles = makeStyles(theme => ({
  card: {
    // maxWidth: 350,
    marginBottom: theme.spacing(1)
  },
  title: {
    textDecoration: "none",
    color: theme.palette.primary.main
  },
  item: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  }
}));

const ThemeListItem = props => {
  const { theme, openForm, confirmDelete } = props;

  const classes = useStyles();

  const { id, name, description } = theme;

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography gutterBottom variant="h5" className={classes.item}>
          <Link component={Link} to={`/themes/${id}`} className={classes.title}>
            {name}
          </Link>
          <div>
            <IconButton onClick={() => openForm(theme)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => confirmDelete(theme)}>
              <DeleteIcon />
            </IconButton>
            <IconButton>
              <MoveIcon />
            </IconButton>
          </div>
        </Typography>
        <Typography component="p">{description}</Typography>
      </CardContent>
    </Card>
  );
};

export default ThemeListItem;
