import React from "react";
import PropTypes from "prop-types";
import {useCreateLessonMutation, useUpdateLessonMutation} from "./graphql";
import {MODALS} from "../../../common/constants";
import {useOpenModal} from "../../shared/Modal/graphql";
import LessonForm from "./LessonForm";
import {useSelectedCourse} from "../../course/graphql";
import {useSelectedSection} from "../../section/graphql";
import {useSelectedLesson} from "../graphql";

const modalId = MODALS.LESSON_FORM_MODAL;

const LessonFormContainer = () => {
  const createLesson = useCreateLessonMutation();
  const updateLesson = useUpdateLessonMutation();
  const openModal = useOpenModal();
  const course = useSelectedCourse();
  const section = useSelectedSection();
  const lesson = useSelectedLesson();

  const onCancel = () => openModal(modalId, false);

  const onCreateLesson = data =>
    createLesson({ ...data, courseId: course.id, sectionId: section.id });

  const onUpdateLesson = data =>
    updateLesson({ ...data, courseId: course.id, sectionId: section.id });

  const isUpdating = lesson && Boolean(lesson.id);

  const modalTitle = isUpdating
    ? `Edit lesson ${lesson.title}`
    : "Add new lesson";
  const submitButtonLabel = isUpdating ? "Update" : "Add";
  const onSubmit = isUpdating ? onUpdateLesson : onCreateLesson;

  return (
    <LessonForm
      modalId={modalId}
      modalTitle={modalTitle}
      onSubmit={onSubmit}
      onCancel={onCancel}
      submitButtonLabel={submitButtonLabel}
      lesson={lesson}
    />
  );
};

LessonFormContainer.propTypes = {
  lesson: PropTypes.object
};

LessonFormContainer.defaultProps = {
  lesson: null
};

export default LessonFormContainer;
