export const notEditable = { contentEditable: false, readOnly: true };

export const BLOCK_TYPES = {
  UNSTYLED: "unstyled",
  PARAGRAPH: "unstyled",
  OL: "ordered-list-item",
  UL: "unordered-list-item",
  H1: "header-one",
  H2: "header-two",
  H3: "header-three",
  H4: "header-four",
  H5: "header-five",
  H6: "header-six",
  CODE: "code-block",
  BLOCKQUOTE: "blockquote",
  ATOMIC: "atomic",
  IMAGE: "image-with-caption",
  VIDEO: "video-with-caption",
};

export const atomicTypes = {
  DIVIDER: "divider"
};

export const mutability = {
  MUTABLE: "MUTABLE",
  IMMUTABLE: "IMMUTABLE"
};

export const VIRTUAL_NODE = {
  clientHeight: 0,
  clientWidth: 0,
  getBoundingClientRect: () => ({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  })
};
