import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {GET_THEMES} from "../ThemeList/graphql";

export const DELETE_THEME = gql`
  mutation DeleteTheme($themeId: ID!) {
    deleteTheme(themeId: $themeId)
  }
`;

export const useDeleteThemeMutation = () => {
  const [deleteTheme] = useMutation(DELETE_THEME, {
    update: (proxy, { data: { deleteTheme: deletedThemeId } }) => {
      const { themes } = proxy.readQuery({ query: GET_THEMES });
      const updatedThemes = themes.filter(({ id }) => id !== deletedThemeId);
      proxy.writeQuery({
        query: GET_THEMES,
        data: { themes: updatedThemes }
      });
      proxy.writeData({
        data: {
          selectedThemeId: null
        }
      });
    }
  });

  return async themeId => {
    await deleteTheme({ variables: { themeId } });
  };
};
