export const preventDefault = callback => event => {
  event.preventDefault();
  callback();
};

const DATA_URI = new RegExp(
    // eslint-disable-next-line
    /^(data:)([\w\/\+]+);(charset=[\w-]+|base64).*,(.*)/gi
);

export const isDataURI = src => DATA_URI.test(src);
