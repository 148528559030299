import { VIRTUAL_NODE } from "../constants";

export const getCurrentSelectionNode = () => {
  const selection = window.getSelection();

  if (!selection || selection.anchorOffset === selection.focusOffset) {
    return VIRTUAL_NODE;
  }

  const getBoundingClientRect = () =>
    selection.getRangeAt(0).getBoundingClientRect();
  const selectionRect = getBoundingClientRect();

  return {
    clientWidth: selectionRect.width,
    clientHeight: selectionRect.height,
    getBoundingClientRect
  };
};
