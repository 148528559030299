import styled from "@material-ui/styles/styled";

import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";

export { Typography, Collapse };

export const Wrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(6)
}));
