import HomeIcon from "@material-ui/icons/Home";
import {Link} from "@reach/router";
import React from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  item: {
    display: "flex",
    color: "white",
    textDecoration: "none"
  },
  icon: {
    marginRight: theme.spacing(0.6),
    width: 20,
    height: 20,
    marginTop: "2px"
  }
}));

const HomeBreadcrumb = () => {
  const classes = useStyles();
  return (
    <Link color="inherit" to="/" className={classes.item}>
      <HomeIcon className={classes.icon} />
    </Link>
  );
};

export default HomeBreadcrumb;
