import {
  IconButton as IconButtonMUI,
  Toolbar as ToolbarMUI
} from "@material-ui/core";
import { styled } from "@material-ui/styles";

export const Toolbar = styled(ToolbarMUI)(() => ({
  paddingRight: 24 // keep right padding when drawer closed
}));

export const IconButton = styled(IconButtonMUI)(({ "data-open": dataOpen }) => {
  const styles = {
    marginRight: 36
  };

  const closeStyles = {
    display: "none"
  };

  return dataOpen ? { ...styles, ...closeStyles } : styles;
});
