import gql from "graphql-tag";
import {useApolloClient, useQuery} from "@apollo/react-hooks";

export const BREADCRUMBS = gql`
  query GetBreadcrumbs {
    breadcrumbs @client {
      title
      url
      type
    }
  }
`;

export const useBreadcrumbs = () => {
  const { data, loading, error } = useQuery(BREADCRUMBS);

  return { data: data ? data.breadcrumbs : data, loading, error };
};

export const useClearBreadcrumbs = () => {
  const client = useApolloClient();
  return () => {
    const data = {
      breadcrumbs: []
    };
    client.writeData({ data });
    return [];
  };
};

export const useAddBreadcrumb = () => {
  const client = useApolloClient();
  return ({ url, title, type }) => {
    const { breadcrumbs } = client.readQuery({ query: BREADCRUMBS });

    const newBreadcrumb = {
      url,
      title,
      type,
      __typename: "Breadcrumb"
    };
    const data = {
      breadcrumbs: [...breadcrumbs, newBreadcrumb]
    };
    client.writeData({ data });
    return newBreadcrumb;
  };
};
