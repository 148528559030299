import React from "react";
import { useLessonEditor } from "../LessonEditorContext";
import { getCurrentSelectionNode } from "./utils";
import InlineActions from "./InlineActions";
import LessonEditorPopup from "../LessonEditorPopup";

const LessonEditorInlineToolbar = () => {
  const { editorState } = useLessonEditor();
  const isCollapsed = editorState.getSelection().isCollapsed();

  const selectionNodeEl = getCurrentSelectionNode();

  return (
    <LessonEditorPopup
      component={InlineActions}
      referenceElement={selectionNodeEl}
      show={!isCollapsed}
    />
  );
};

export default LessonEditorInlineToolbar;
