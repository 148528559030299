import { useSelectedCourse } from "../../graphql";
import React from "react";
import SubHeader from "../SubHeader";
import PropTypes from "prop-types";

export const tabs = [
  { label: "Syllabus", url: "../syllabus" },
  { label: "Settings", url: "../settings" }
  //{ label: "Activity", url: "../activity" }
];

const CourseSubHeaderContainer = ({ uri }) => {
  const { data } = useSelectedCourse();

  if (!data) {
    return null;
  }

  return <SubHeader uri={uri} tabs={tabs} />;
};

CourseSubHeaderContainer.propTypes = {
  uri: PropTypes.string.isRequired
};

export default CourseSubHeaderContainer;
