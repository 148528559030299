import { insertCustomBlock } from "../hooks/useInsertCustomBlock";
import { createNewBlock } from "./index";
import { BLOCK_TYPES } from "../constants";
import { Map } from "immutable";
import { MEDIA_TYPE } from "../../../../common/constants";

export const getMediaType = type =>
  Object.keys(MEDIA_TYPE)
    .filter(blockType => type.startsWith(blockType.toLowerCase()))
    .shift();

export const buildUploadAndAddMediaBlockHandler = options => async (
  fileBlob,
  editorState
) => {
  const { readFile, uploadFile, setEditorState } = options;
  const insertMediaBlock = insertCustomBlock(editorState, setEditorState);

  const mediaType = getMediaType(fileBlob.type);

  if (!mediaType) {
    console.warn(`File type ${fileBlob.type} is not supported`);
    return;
  }

  const file = await readFile(fileBlob, false);

  // build data
  const caption = file.name;
  const src = await uploadFile({ ...file, caption });
  const align = "center";

  const newBlock = createNewBlock(
    BLOCK_TYPES[mediaType],
    caption,
    Map({ src, caption, align })
  );
  return insertMediaBlock(newBlock, true);
};

const handlePastedFiles = options => files => {
  const {
    editorState,
    setEditorState,
    readFile,
    uploadFile,
    setShowOverlay,
    setReadOnly
  } = options;

  const uploadAndAddMediaBlock = buildUploadAndAddMediaBlockHandler({
    readFile,
    uploadFile,
    setEditorState
  });

  setShowOverlay(true);
  setReadOnly(true);

  Object.keys(files)
    .reduce(
      (prev, cur) =>
        prev.then(prevEditorState =>
          uploadAndAddMediaBlock(files[cur], prevEditorState)
        ),
      Promise.resolve(editorState)
    )
    .then(() => {
      setShowOverlay(false);
      setReadOnly(false);
    });

  return "handled";
};

export default handlePastedFiles;
