import {useLessonEditor} from "../LessonEditorContext";
import {EditorState, RichUtils} from "draft-js";
import {collapseSelection} from "../helpers";

const useToggleInlineStyle = () => {
  const { editorState, setEditorState } = useLessonEditor();

  return type => {
    setEditorState(
      EditorState.forceSelection(
        RichUtils.toggleInlineStyle(editorState, type),
        collapseSelection(editorState)
      )
    );
  };
};

export default useToggleInlineStyle;
