import styled from "@material-ui/styles/styled";
import ButtonMUI from "@material-ui/core/Button";
import { Close, CloudUpload } from "@material-ui/icons";

export const Form = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  flexWrap: "wrap",
  padding: theme.spacing(2)
}));

export const FormActions = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  marginTop: theme.spacing(2),
  width: "100%"
}));

export const Button = styled(ButtonMUI)(({ theme }) => ({
  marginLeft: theme.spacing(1)
}));

export const UploadIcon = styled(CloudUpload)(({ theme }) => ({
  marginRight: theme.spacing(1)
}));

export const CloseIcon = styled(Close)(({ theme }) => ({
  marginRight: theme.spacing(1)
}));
