import React from "react";
import ThemeEditor from "./ThemeEditor";
import PropTypes from "prop-types";
import { buildThemeContent, buildThemeFormValues } from "./utils";
import { TYPE_THEME } from "../../../common/types";

const ThemeEditorContainer = props => {
  const { theme, onSave } = props;

  const onSubmit = async formValues => {
    const themeContent = buildThemeContent(formValues);
    await onSave(themeContent);
  };

  const defaultValues = buildThemeFormValues(theme);

  return <ThemeEditor defaultValues={defaultValues} onSubmit={onSubmit} />;
};

ThemeEditorContainer.propTypes = {
  theme: TYPE_THEME.isRequired,
  onSave: PropTypes.func.isRequired
};

export default ThemeEditorContainer;
