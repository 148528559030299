import {EditorState, Modifier} from "draft-js";
import {useLessonEditor} from "../LessonEditorContext";

const useModifyBlockData = () => {
  const { editorState, setEditorState } = useLessonEditor();

  return (key, data) => {
    const currentContentState = editorState.getCurrentContent();

    const modifiedContentState = Modifier.mergeBlockData(
      currentContentState,
      editorState.getSelection(),
      data
    );

    setEditorState(
      EditorState.push(
        editorState,
        modifiedContentState,
        "change-block-data"
      )
    );
  };
};
export default useModifyBlockData;
