import React from "react";
import PropTypes from "prop-types";
import MenuIcon from "@material-ui/icons/Menu";
import { IconButton, Toolbar } from "./styledComponents";
import Profile from "../Profile/Profile";
import Breadcrumbs from "../Breadcrumbs";

const Header = props => {
  const { setOpen, open } = props;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  return (
    <Toolbar>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="Open drawer"
        onClick={handleDrawerOpen}
        data-open={open}
      >
        <MenuIcon />
      </IconButton>
      <Breadcrumbs />
      <Profile />
    </Toolbar>
  );
};

Header.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
};

export default Header;
