import React from "react";
import { Button, SettingsActions, Wrapper, Paper } from "./styledComponents";
import Typography from "@material-ui/core/Typography";
import { TYPE_COURSE } from "../../../common/types";
import Spinner from "../../shared/Spinner";
import CourseConfirmDeleteDialog from "../CourseConfirmDeleteDialog";
import PropTypes from "prop-types";
import CourseForm from "../CourseForm";
import { preventDefault } from "../../../common/util";

const CourseSettings = props => {
  const {
    course,
    updateCourse,
    onArchive,
    onSCORMDownload,
    SCORMDownloading
  } = props;

  return (
    <Wrapper container direction="column" spacing={2}>
      <Typography variant="h5" component="h2">
        Course Settings
      </Typography>
      <Paper>
        <CourseForm onSubmit={updateCourse} course={course} showSave />
      </Paper>
      <SettingsActions>
        <Button
          variant="contained"
          color="primary"
          onClick={preventDefault(onSCORMDownload)}
          disabled={SCORMDownloading}
        >
          Export Course {SCORMDownloading && <Spinner inline />}
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={preventDefault(onArchive)}
        >
          Archive Course
        </Button>
        <CourseConfirmDeleteDialog />
      </SettingsActions>
    </Wrapper>
  );
};

CourseSettings.propTypes = {
  course: TYPE_COURSE.isRequired,
  updateCourse: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
  onSCORMDownload: PropTypes.func.isRequired,
  SCORMDownloading: PropTypes.bool.isRequired
};

export default CourseSettings;
