import React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  FirstPageIcon,
  LastPageIcon,
  Wrapper,
  KeyboardArrowLeft,
  KeyboardArrowRight
} from "./styledComponents";

const PaginationActions = props => {
  const { page, count, rowsPerPage, onChangePage, className } = props;

  const firstPage = 0;
  const lastPage = Math.ceil(count / rowsPerPage) - 1;

  const isFirstPage = page === firstPage;
  const isLastPage = page >= lastPage;

  return (
    <Wrapper className={className}>
      <IconButton
        onClick={onChangePage(firstPage)}
        disabled={isFirstPage}
        aria-label="First Page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={onChangePage(page - 1)}
        disabled={isFirstPage}
        aria-label="Previous Page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={onChangePage(page + 1)}
        disabled={isLastPage}
        aria-label="Next Page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={onChangePage(lastPage)}
        disabled={isLastPage}
        aria-label="Last Page"
      >
        <LastPageIcon />
      </IconButton>
    </Wrapper>
  );
};

PaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

export default PaginationActions;
