import {Breadcrumbs as BreadcrumbsMUI} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import BreadcrumbItem from "./BreadcrumbItem";
import HomeBreadcrumb from "./HomeBreadcrumb";
import {BREADCRUMB_TYPES} from "./utils";
import CourseBreadcrumb from "./CourseBreadcrumb";
import SectionBreadcrumb from "./SectionBreadcrumb";
import ThemeBreadcrumb from "./ThemeBreadcrumb";

const Breadcrumbs = React.memo(({ items }) => {
  const breadcrumbItems = items.map(({ url, title, type }, index) => {
    switch (type) {
      case BREADCRUMB_TYPES.COURSE:
        return <CourseBreadcrumb url={url} key={index} />;
      case BREADCRUMB_TYPES.SECTION:
        return <SectionBreadcrumb url={url} key={index} />;
        case BREADCRUMB_TYPES.THEME:
        return <ThemeBreadcrumb url={url} key={index} />;
      default:
        return <BreadcrumbItem title={title} url={url} key={index} />;
    }
  });

  return (
    <BreadcrumbsMUI aria-label="breadcrumb" style={{ flexGrow: 1 }}>
      <HomeBreadcrumb />
      {breadcrumbItems}
    </BreadcrumbsMUI>
  );
});

Breadcrumbs.propTypes = { items: PropTypes.array.isRequired };

export default Breadcrumbs;
