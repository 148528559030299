import React from "react";
import PropTypes from "prop-types";
import Modal from "../../shared/Modal";
import {Box, Typography} from "@material-ui/core";
import {preventDefault} from "../../../common/util";
import Overlay from "../../shared/Overlay";

const LessonConfirmDeleteDialog = props => {
  const { modalId, onConfirm, onCancel, lesson, loading } = props;
  const handleConfirm = preventDefault(() => onConfirm());
  const handleCancel = preventDefault(() => onCancel());

  const { title, description } = lesson || {};

  return (
    <Modal
      modalId={modalId}
      title="Are you sure you want to delete lesson?"
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      confirmButtonLabel="Yes"
      cancelButtonLabel="No"
      maxWidth="md"
      fullWidth
    >
      <Typography component="span">
        <Box fontWeight="fontWeightBold">{title}</Box>
      </Typography>
      <Typography component="p">{description}</Typography>
      {loading && <Overlay />}
    </Modal>
  );
};

LessonConfirmDeleteDialog.propTypes = {
  modalId: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  lesson: PropTypes.shape({}),
  loading: PropTypes.bool.isRequired,
};

LessonConfirmDeleteDialog.defaultProps = {
  lesson: null
};

export default LessonConfirmDeleteDialog;
