import React from "react";
import { Actions } from "./styledComponents";
import {
  AddDivider,
  AddImage,
  AddVideo,
  ConvertToCode
} from "../../actions";

const SidebarActions = () => (
  <Actions>
    <AddImage />
    <AddVideo />
    <AddDivider />
    <ConvertToCode />
  </Actions>
);

export default SidebarActions;
