import { styled } from "@material-ui/styles";
import { Drawer as DrawerMUI } from "@material-ui/core";

export const Drawer = styled(DrawerMUI)(({ theme, open }) => {
  const paperStyles = {
    position: "relative",
    width: theme.dimensions.sidebar,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  };

  const paperCloseStyles = {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  };

  const paper = !open ? { ...paperStyles, ...paperCloseStyles } : paperStyles;
  return { "& .MuiPaper-root": paper };
});

export const Toolbar = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: "0 8px",
  ...theme.mixins.toolbar
}));
