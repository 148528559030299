import React from "react";
import PropTypes from "prop-types";
import { preventDefault } from "../../../../common/util";
import {
  Button,
  CloseIcon,
  Form,
  FormActions,
  UploadIcon
} from "./styledComponents";
import FileUploader from "../../../shared/FileUploader";
import { TextField } from "../../../shared/FormFields";
import useForm from "../../../../hooks/useForm";
import { TYPE_MEDIA_TYPE } from "../../../../common/types";

export const FORM_DEFAULT_VALUES = { align: "center" };

const MediaForm = props => {
  const {
    onSubmit,
    onCancel,
    defaultValues,
    showUpload,
    showCaption,
    mediaType
  } = props;

  const form = useForm({
    defaultValues,
    submitHandler: onSubmit
  });

  const handleUpload = preventDefault(form.submit);
  const handleClose = preventDefault(onCancel);

  // TODO FileUploader should be in fields and should accept form field props
  const handleSelectFile = selectedFile => form.setValue("file", selectedFile);

  return (
    <Form autoComplete="off">
      {showUpload && mediaType && (
        <FileUploader onChange={handleSelectFile} mediaType={mediaType} />
      )}
      {!showUpload && (
        <TextField label="URL" {...form.field("src")} fullWidth />
      )}
      {showCaption && (
        <TextField label="Caption" {...form.field("caption")} fullWidth />
      )}
      <FormActions>
        <Button variant="contained" color="primary" onMouseDown={handleUpload}>
          <UploadIcon />
          Ok
        </Button>
        <Button variant="contained" onMouseDown={handleClose}>
          <CloseIcon />
          Cancel
        </Button>
      </FormActions>
    </Form>
  );
};

MediaForm.propTypes = {
  mediaType: TYPE_MEDIA_TYPE,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({
    file: PropTypes.shape({}),
    src: PropTypes.string,
    caption: PropTypes.string,
    align: PropTypes.oneOf(["left", "center", "right"])
  }),
  showUpload: PropTypes.bool,
  showCaption: PropTypes.bool
};

MediaForm.defaultProps = {
  showUpload: false,
  showCaption: true,
  defaultValues: FORM_DEFAULT_VALUES
};

export default MediaForm;
