import { Paper as PaperMUI } from "@material-ui/core";
import { styled } from "@material-ui/styles";

export const Wrapper = styled(PaperMUI)(({ theme }) => ({
  marginTop: theme.spacing(6),
  padding: theme.spacing(4),
  display: "flex",
  overflow: "auto",
  flexDirection: "column"
}));
