import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";

const endpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;

export const initialStateValues = {
  selectedCourseId: null,
  selectedSectionId: null,
  selectedLessonId: null,
  selectedThemeId: null,
  breadcrumbs: [],
  pageName: null,
  modals: []
};

export const typeDefs = gql`
  type Breadcrumb {
    url: ID!
    title: String
    type: String
  }

  type Modal {
    modalId: ID!
    open: Boolean
  }

  extend type Query {
    breadcrumbs: [Breadcrumb]
    selectedCourseId: ID
    selectedSectionId: ID
    selectedLessonId: ID
    selectedThemeId: ID
    pageName: ID
    modals: [Modal]
  }
`;

const buildApolloClient = token => {
  const httpLink = createHttpLink({
    uri: endpoint
  });

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: token.current ? `Bearer ${token.current}` : ""
    }
  }));

  const cache = new InMemoryCache();
  cache.writeData({
    data: initialStateValues
  });

  const client = new ApolloClient({
    link: ApolloLink.from([authLink, httpLink]),
    cache,
    typeDefs,
    resolvers: {}
  });

  // re-initialize Apollo cache after store reset
  client.onResetStore(
    async () =>
      await cache.writeData({
        data: initialStateValues
      })
  );

  return client;
};

export default buildApolloClient;
