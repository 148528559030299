import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {GET_COURSES} from "../CourseList/graphql";

export const DELETE_COURSE = gql`
  mutation DeleteCourse($courseId: ID!) {
    deleteCourse(courseId: $courseId)
  }
`;

export const useDeleteCourseMutation = () => {
  const [deleteCourse] = useMutation(DELETE_COURSE, {
    update: (proxy, { data: { deleteCourse: deletedCourseId } }) => {
      const { courses } = proxy.readQuery({ query: GET_COURSES });
      const updatedCourses = courses.filter(({ id }) => id !== deletedCourseId);
      proxy.writeQuery({
        query: GET_COURSES,
        data: { courses: updatedCourses }
      });
      proxy.writeData({
        data: {
          selectedCourseId: null
        }
      });
    }
  });

  return async courseId => {
    await deleteCourse({ variables: { courseId } });
  };
};
