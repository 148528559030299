const firebaseConfig = {
    apiKey: "AIzaSyC4UDqIo1LSVMszzWMPWf6rGUdxqXSpkkI",
    authDomain: "battery-development-afa67.firebaseapp.com",
    databaseURL: "https://battery-development-afa67.firebaseio.com",
    projectId: "battery-development-afa67",
    storageBucket: "battery-development-afa67.appspot.com",
    messagingSenderId: "653157102599",
    appId: "1:653157102599:web:c9475ab16fe05795"
};
export default firebaseConfig;
