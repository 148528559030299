import React, { useEffect, useRef, useState } from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import FirebaseContext from "./FirebaseContext";
import firebaseConfig from "./firebase.conf";
import { ApolloProvider } from "@apollo/react-hooks";
import buildApolloClient from "../../apolloClient";
import getGoogleAuthProvider from "./getGoogleAuthProvider";

const app = firebase.initializeApp(firebaseConfig);

const FirebaseProvider = props => {
  // TODO replace with useReducer
  const [isInitialized, setInitialized] = useState(false);
  const [user, setUser] = useState(false);
  const token = useRef("");
  const client = useRef(Function.prototype);

  useEffect(() => {
    // auth changes hook
    const authStateListener = app.auth().onAuthStateChanged(async user => {
      setUser(user);
      token.current = user ? await user.getIdToken() : null;
      client.current = buildApolloClient(token);

      if (!isInitialized) {
        setInitialized(true);
      }
    });

    const tokenChangeListener = app.auth().onIdTokenChanged(async user => {
      token.current = user ? await user.getIdToken() : null;
    });

    return () => {
      authStateListener();
      tokenChangeListener();
    };
  }, [isInitialized]);

  if (isInitialized === false) {
    return <div>Waiting for Firebase initialization...</div>;
  }

  // show google oauth popup
  const login = async () => {
    try {
      const provider = getGoogleAuthProvider();
      await firebase.auth().signInWithPopup(provider);
      await client.current.resetStore();
    } catch (error) {
      return false;
    }
    return true;
  };

  const logout = async () => {
    if (!Boolean(user)) {
      return true;
    }
    try {
      await app.auth().signOut();
      await client.current.resetStore();
    } catch (error) {
      console.warn(user, error);
      return false;
    }
    return true;
  };

  const contextValue = {
    login,
    logout,
    // TODO check why we need this???
    currentUser: user,
    isAuthenticated: Boolean(user),
    getToken: () => token.current
  };

  return (
    <FirebaseContext.Provider value={contextValue}>
      <ApolloProvider client={client.current}>{props.children}</ApolloProvider>
    </FirebaseContext.Provider>
  );
};

export default FirebaseProvider;
