import PropTypes from "prop-types";
import {preventDefault} from "../../../common/util";
import Modal from "../../shared/Modal";
import {Box, Typography} from "@material-ui/core";
import React from "react";
import Overlay from "../../shared/Overlay";

const SectionConfirmDeleteDialog = props => {
  const { modalId, onConfirm, onCancel, section, loading } = props;
  const handleConfirm = preventDefault(() => onConfirm());
  const handleCancel = preventDefault(() => onCancel());

  const { title } = section || {};

  return (
    <Modal
      modalId={modalId}
      title="Are you sure you want to delete section?"
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      confirmButtonLabel="Yes"
      cancelButtonLabel="No"
      maxWidth="md"
      fullWidth
    >
      <Typography component="span">
        <Box fontWeight="fontWeightBold">{title}</Box>
      </Typography>
      {loading && <Overlay />}
    </Modal>
  );
};

SectionConfirmDeleteDialog.propTypes = {
  modalId: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  section: PropTypes.shape({}),
  loading: PropTypes.bool.isRequired
};

SectionConfirmDeleteDialog.defaultProps = {
  section: null
};

export default SectionConfirmDeleteDialog;
