import gql from "graphql-tag";
import {useApolloClient, useMutation, useQuery} from "@apollo/react-hooks";
import {GET_LESSON} from "../graphql";

const UPDATE_LESSON_TRANSCRIPT = gql`
  mutation UpdateLesson($lesson: TranscriptLessonInput!) {
    updateTranscript(lesson: $lesson) {
      id
      transcript
    }
  }
`;

export const useUpdateLessonTranscriptMutation = () => {
  const [updateLesson] = useMutation(UPDATE_LESSON_TRANSCRIPT);

  return async lesson =>
    await updateLesson({
      variables: {
        lesson
      }
    });
};

export const useGetLesson = (courseId, sectionId, lessonId) => {
    const client = useApolloClient();
    const { data, error, loading } = useQuery(GET_LESSON, {
        variables: { courseId, sectionId, lessonId },
        suspend: false
    });

    // mark course as selected in cache
    if (!loading && data && data.lesson) {
        client.writeData({
            data: {
                selectedCourseId: courseId,
                selectedSectionId: sectionId,
                selectedLessonId: lessonId
            }
        });
    }

    return { data: data ? data.lesson : data, error, loading };
};

const UPLOAD_FILE = gql`
  mutation Upload($file: FileInput!) {
    upload(file: $file)
  }
`;

export const useUploadFile = () => {
    const [uploadFile] = useMutation(UPLOAD_FILE);

    return async file => {
        const {
            data: { upload: src }
        } = await uploadFile({
            variables: {
                file
            }
        });

        return src;
    };
};
