import {useLessonEditor} from "../LessonEditorContext";
import {EditorState, RichUtils} from "draft-js";
import collapseSelection from "../helpers/collapseSelection";

const useToggleLink = () => {
  const { editorState, setEditorState } = useLessonEditor();

  return url => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "LINK",
      "MUTABLE",
      { url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const editorStateWithEntity = EditorState.set(editorState, {
      currentContent: contentStateWithEntity
    });

    const editorStateWithToggledLink = RichUtils.toggleLink(
      editorStateWithEntity,
      editorStateWithEntity.getSelection(),
      entityKey
    );

    setEditorState(
      EditorState.acceptSelection(
        editorStateWithToggledLink,
        collapseSelection(editorStateWithToggledLink)
      )
    );
  };
};

export default useToggleLink;
