import React from "react";
import {
  AddLink,
  Bold,
  ConvertToSubtitle,
  ConvertToTitle,
  Italic,
  Underline
} from "../../actions";
import { Actions } from "./styledComponents";

const InlineActions = () => (
  <Actions>
    <Bold />
    <Italic />
    <Underline />
    <AddLink />
    <ConvertToTitle />
    <ConvertToSubtitle />
  </Actions>
);

export default InlineActions;
