import readFile from "../../../common/readFile";

const onFileInputChange = options => async event => {
  const { setFile, onChange } = options;

  const {
    target: {
      files: [fileToUpload]
    }
  } = event;

  const fileHandler = await readFile(fileToUpload);
  setFile(fileHandler);
  const { preview, ...selectedFile } = fileHandler;
  onChange(selectedFile);
};

export default onFileInputChange;
