import React from "react";
import {makeStyles} from "@material-ui/styles";
import ThemeListItem from "./ThemeListItem";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import {TYPE_THEME} from "../../../common/types";
import ThemeForm from "../ThemeForm";
import ThemeConfirmDeleteDialog from "../ThemeConfirmDeleteDialog";

const useStyles = makeStyles(theme => ({
  list: {
    display: "flex",
    flexDirection: "column",
  },
  listActions: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "16px",
    justifyContent: "flex-end"
  }
}));

const ThemeList = props => {
  const { themes, openForm, confirmDelete } = props;
  const classes = useStyles();

  const themesList = themes.map(theme => (
    <ThemeListItem
      key={theme.id}
      theme={theme}
      openForm={openForm}
      confirmDelete={confirmDelete}
    />
  ));

  return (
    <div className={classes.list}>
      <div className={classes.listActions}>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={() => openForm()}
        >
          New theme
        </Button>
      </div>
      {themesList}
      <ThemeForm />
      <ThemeConfirmDeleteDialog/>
    </div>
  );
};

ThemeList.propTypes = {
  themes: PropTypes.arrayOf(TYPE_THEME).isRequired,
  openForm: PropTypes.func.isRequired,
  confirmDelete: PropTypes.func.isRequired
};

export default ThemeList;
