import React from "react";
import { useGetUsers, useRemoveUserRoleMutation } from "./graphql";
import Spinner from "../../shared/Spinner";
import UserList from "./UserList";
import PropTypes from "prop-types";
import { TYPE_USERS } from "../../../common/types";

const UserListContainer = props => {
  const { data: users, error, loading } = useGetUsers();
  const removeUserRole = useRemoveUserRoleMutation();

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return `Error! ${error.message}`;
  }

  const onRoleRemove = userRole => () => removeUserRole(userRole);

  return <UserList users={users} onRoleRemove={onRoleRemove} {...props} />;
};

UserListContainer.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
  selectedUsers: TYPE_USERS.isRequired
};

export default UserListContainer;
