import React from "react";
import {FormatBold} from "@material-ui/icons";
import ActionButton from "./ActionButton";
import {useToggleInlineStyle} from "../hooks";

export default function Bold() {
  const toggleInlineStyle = useToggleInlineStyle();
  return (
    <ActionButton
      label={"Bold"}
      action={() => toggleInlineStyle("BOLD")}
      icon={FormatBold}
    />
  );
}
