import {useLessonEditor} from "../LessonEditorContext";
import {EditorState, Modifier} from "draft-js";
import collapseSelection from "../helpers/collapseSelection";

const useRemoveBlock = () => {
  const { editorState, setEditorState } = useLessonEditor();

  return blockKey => {
    const block = editorState.getCurrentContent().getBlockForKey(blockKey);
    if (!block) {
      console.log(`Invalid block key ${blockKey}`);
      return;
    }

    // select block content
    const blockSelection = editorState.getSelection().merge({
      anchorKey: blockKey,
      anchorOffset: 0,
      focusKey: blockKey,
      focusOffset: Math.max(block.getLength() - 1, 0)
    });

    // remove all entities, data and styles
    const modifiedContentState = Modifier.removeRange(
      editorState.getCurrentContent(),
      blockSelection,
      "forward"
    );

    // remove block from content state
    const blockMap = modifiedContentState.getBlockMap().delete(blockKey);
    const newContentState = modifiedContentState.merge({
      blockMap,
      selectionAfter: blockSelection
    });

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "remove-range"
    );

    // move caret to next block
    const prevBlock = editorState.getCurrentContent().getBlockBefore(blockKey);
    const nextBlock = editorState.getCurrentContent().getBlockAfter(blockKey);
    const newSelection =
      (nextBlock &&
        nextBlock.getKey() &&
        collapseSelection(editorState, nextBlock.getKey(), 0)) ||
      (prevBlock &&
        prevBlock.getKey() &&
        collapseSelection(
          editorState,
          prevBlock.getKey(),
          prevBlock.getLength()
        ));

    // publish new editor state and rerender blocks
    setEditorState(EditorState.forceSelection(newEditorState, newSelection));
  };
};

export default useRemoveBlock;
