import MUIGrid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/styles";

export const Wrapper = withStyles(theme => ({
    root: {
        marginTop: theme.spacing(6)
    }
}))(MUIGrid);


