import { BLOCK_TYPES, VIRTUAL_NODE } from "../constants";
import { getCurrentBlock } from "../helpers";

const getCurrentBlockNode = async editorState => {
  const selection = editorState.getSelection();

  // show sidebar only when there is no active selection
  if (!selection.isCollapsed()) {
    return VIRTUAL_NODE;
  }

  const contentBlock = getCurrentBlock(editorState);

  // TODO add divider and video
  if (!contentBlock || contentBlock.getType() === BLOCK_TYPES.IMAGE) {
    return VIRTUAL_NODE;
  }

  // show only when block is empty
  if (contentBlock.getLength() > 0) {
    return VIRTUAL_NODE;
  }

  return new Promise(resolve => {
    setTimeout(() => {
      resolve(
        document.querySelector(
          `[data-block="true"][data-offset-key="${contentBlock.getKey()}-0-0"]`
        ) || VIRTUAL_NODE
      );
    });
  });
};

export default getCurrentBlockNode;
