import React from "react";
import CourseNav from "./CourseNav";
import {useSelectedCourse} from "../../graphql";
import {PAGE_NAMES} from "../../../util";

const CourseNavContainer = () => {
  const { data } = useSelectedCourse([PAGE_NAMES.COURSE, PAGE_NAMES.LESSON]);

  if (!data) {
    return null;
  }

  return <CourseNav course={data} />;
};

export default CourseNavContainer;
