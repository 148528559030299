import React from "react";
import PropTypes from "prop-types";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Link, ListItem } from "./styledComponents";

const CourseListItem = props => {
  const { course } = props;

  const { title, id, description } = course;

  const syllabusUrl = `/courses/${id}/syllabus`;

  //const onEdit = () => openForm(course);
  //const onDelete = () => confirmDelete(course);

  return (
    <ListItem>
      <CardContent>
        <Typography gutterBottom variant="h5">
          <Link to={syllabusUrl}>{title}</Link>
        </Typography>
        <Typography component="p">{description}</Typography>
      </CardContent>
      <CardActions>
        <Link to={syllabusUrl}>Learn More</Link>
      </CardActions>
    </ListItem>
  );
};

CourseListItem.propTypes = {
  course: PropTypes.shape({}).isRequired,
  openForm: PropTypes.func.isRequired
};

export default CourseListItem;
