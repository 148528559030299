import { makeStyles } from "@material-ui/styles";

export const useGlobalStyles = makeStyles(() => ({
  "@global": {
    "@import": `url('https://fonts.googleapis.com/css?family=Lato|Open+Sans|Roboto&display=swap')`,
    "html, body": {
      minHeight: "100%",
      height: "100%"
    },
    // TODO check why this is needed, move to App styles?
    body: {
      fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`
    },
    // TODO check where this is needed
    code: {
      fontFamily: `font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace`
    },
    // Router styling
    "#root, #root div[role=group]": {
      minHeight: "100%",
      display: "flex",
      width: "100%",
    }
  }
}));
