import MUIExpansionPanel from "@material-ui/core/ExpansionPanel";
import MUIExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MUIExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import {styled, withStyles} from "@material-ui/styles";

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(15),
  flexBasis: "33.33%",
  flexShrink: 0
}));

export const ExpansionPanel = withStyles({
  root: {
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MUIExpansionPanel);

export const ExpansionPanelSummary = withStyles({
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(MUIExpansionPanelSummary);

export const ExpansionPanelDetails = withStyles({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column"
  }
})(MUIExpansionPanelDetails);
