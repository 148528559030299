import React, {useState} from "react";
import {MODALS} from "../../../common/constants";
import {useDeleteThemeMutation} from "./graphql";
import {useOpenModal} from "../../shared/Modal/graphql";
import {useSelectedTheme} from "../graphql";
import ThemeConfirmDeleteDialog from "./ThemeConfirmDeleteDialog";

const modalId = MODALS.THEME_DELETE_MODAL;

const ThemeConfirmDeleteDialogContainer = () => {
  const deleteTheme = useDeleteThemeMutation();
  const openModal = useOpenModal();
  const theme = useSelectedTheme();
  const [loading, setLoading] = useState(false);

  const onCancel = () => {
    if (loading) {
      return;
    }
    openModal(modalId, false);
  };

  const onConfirm = async () => {
    setLoading(true);
    await deleteTheme(theme.id);
    setLoading(false);
    onCancel();
  };

  return (
    <ThemeConfirmDeleteDialog
      modalId={modalId}
      onConfirm={onConfirm}
      onCancel={onCancel}
      theme={theme}
      loading={loading}
    />
  );
};

export default ThemeConfirmDeleteDialogContainer;
