import React from "react";
import {Link} from "@reach/router";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import BatteryIcon from "../../../icons/BatteryIcon";
import NavItems from "./NavItems";
import CourseNav from "./CourseNav";
import {Drawer, Toolbar} from "./styledComponents";
import {Divider, IconButton} from "@material-ui/core";
import {sidebarItems} from "../../../common/sidebarItems";

const Sidebar = props => {
  const { handleDrawerClose, open } = props;

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar>
        <Link to="/">
          <BatteryIcon />
        </Link>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <NavItems items={sidebarItems} />
      <CourseNav />
    </Drawer>
  );
};

export default Sidebar;
