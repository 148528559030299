import React from "react";
import PropTypes from "prop-types";
import {
  Actions,
  Button,
  Column,
  Footer,
  Header,
  Paper,
  useEditorStyles,
  Wrapper
} from "./styledComponents";
import { useLessonEditor } from "./LessonEditorContext";
import LessonEditorSidebar from "./LessonEditorSidebar";
import LessonEditorInlineToolbar from "./LessonEditorInlineToolbar";
import { TYPE_LESSON } from "../../../common/types";
import get from "lodash.get";
import { Editor } from "draft-js";
import MediaFormModal from "./MediaFormModal";
import Overlay from "../../shared/Overlay";

const LessonEditor = React.forwardRef((props, ref) => {
  const { editorProps, lesson } = props;

  useEditorStyles();

  const {
    save,
    focus,
    editorState,
    setEditorState,
    readOnly,
    courseClasses,
    showOverlay
  } = useLessonEditor();

  const theme = get(lesson, "theme") || {};
  const title = get(lesson, "title", "");

  return (
    <Wrapper>
      <Actions>
        <Button onClick={save} variant="contained" color="primary">
          Save
        </Button>
      </Actions>
      <Header className={courseClasses.header}>
        <Column>{theme.headerLeft}</Column>
        <Column>{title}</Column>
        <Column>{theme.headerRight}</Column>
      </Header>
      <Paper onClick={focus}>
        <Editor
          editorState={editorState}
          onChange={setEditorState}
          ref={ref}
          readOnly={readOnly}
          {...editorProps}
        />
        {showOverlay && <Overlay />}
      </Paper>
      <Footer className={courseClasses.footer} test={1}>
        <Column>{theme.footerLeft}</Column>
        <Column>{theme.footerRight}</Column>
      </Footer>
      <LessonEditorSidebar editorState={editorState} />
      <LessonEditorInlineToolbar editorState={editorState} />
      <MediaFormModal fullWidth maxWidth={"md"} />
    </Wrapper>
  );
});

LessonEditor.propTypes = {
  lesson: TYPE_LESSON,
  editorProps: PropTypes.shape({})
};

LessonEditor.defaultProps = {
  lesson: {},
  editorProps: {}
};

export default LessonEditor;
