const collapseSelection = (editorState, key = null, offset = null) => {
  const selection = editorState.getSelection();

  if (!key) {
    key = selection.focusKey;
  }
  if (offset === null || window.isNaN(Number(offset))) {
    offset = selection.focusOffset;
  }

  return selection.merge({
    anchorKey: key,
    anchorOffset: offset,
    focusKey: key,
    focusOffset: offset,
    isBackward: false
  });
};

export default collapseSelection;
