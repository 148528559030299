import {List, Map, Repeat} from "immutable";
import {CharacterMetadata, ContentBlock, genKey} from "draft-js";
import {BLOCK_TYPES} from "../constants";

const createNewBlock = (
  type = BLOCK_TYPES.UNSTYLED,
  text = "",
  data = Map({})
) =>
  new ContentBlock({
    key: genKey(),
    type,
    data,
    text,
    characterList: new List(Repeat(CharacterMetadata.create(), text.length)),
    depth: 0
  });

export default createNewBlock;
