import React from "react";
import ActionButton from "./ActionButton";
import { Title } from "@material-ui/icons";
import { useToggleBlockType } from "../hooks";

export default function ConvertToTitle() {
  const toggleBlockType = useToggleBlockType();
  return (
    <ActionButton
      label={"Convert to title"}
      action={() => toggleBlockType("header-two")}
      icon={Title}
    />
  );
}
