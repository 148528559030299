export const NEW_THEME = { id: null, name: "", description: "" };

export const buildFormValues = (defaultValues = {}) => {
  // TODO find batter way of finding objects intersection
  const { id, name, description } = {...NEW_THEME, ...defaultValues};
  return { id, name, description };
};

export const validateThemeForm = (values = {}) => {
  const errors = {};
  if (!values || !values.name || values.name.trim().length === 0) {
    errors.name = "Name must be set";
  }
  return errors;
};
