import React, {useState} from "react";
import {MODALS} from "../../../common/constants";
import {useDeleteLessonMutation} from "./graphql";
import {useOpenModal} from "../../shared/Modal/graphql";
import LessonConfirmDeleteDialog from "./LessonConfirmDeleteDialog";
import {useSelectedLesson} from "../graphql";

const modalId = MODALS.LESSON_DELETE_MODAL;

const LessonConfirmDeleteDialogContainer = () => {
  const deleteSection = useDeleteLessonMutation();
  const openModal = useOpenModal();
  const lesson = useSelectedLesson();
  const [loading, setLoading] = useState(false);

  const onCancel = () => {
    if (loading) {
      return;
    }
    openModal(modalId, false);
  };

  const onConfirm = async () => {
    setLoading(true);
    await deleteSection(lesson.courseId, lesson.sectionId, lesson.id);
    setLoading(false);
    onCancel();
  };

  return (
    <LessonConfirmDeleteDialog
      modalId={modalId}
      onConfirm={onConfirm}
      onCancel={onCancel}
      lesson={lesson}
      loading={loading}
    />
  );
};

export default LessonConfirmDeleteDialogContainer;
