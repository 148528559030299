import React, { useContext } from "react";
import { EditorState } from "draft-js";

export const useLessonEditor = () => useContext(LessonEditorContext);

const LessonEditorContext = React.createContext({
  editorState: EditorState.createEmpty(),
  setEditorState: Function.prototype,
  readOnly: false,
  setReadOnly: Function.prototype,
  focus: Function.prototype,
  save: Function.prototype,
  courseClasses: {},
  lessonId: null,
  addAsset: Function.prototype,
  removeAsset: Function.prototype,
  openImageForm: Function.prototype,
  openVideoForm: Function.prototype,
  closeMediaForm: Function.prototype,
  mediaModalProps: {open: false}
});

export default LessonEditorContext;
