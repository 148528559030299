import React from "react";
import { useFirebase } from "../../../components/firebase";
import { Person } from "@material-ui/icons";
import { Avatar, IconButton, Menu, MenuItem } from "@material-ui/core";

const Profile = () => {
  const { currentUser, login, logout } = useFirebase();
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const onLogin = async () => {
    handleClose();
    await login();
  };

  const onLogout = async () => {
    handleClose();
    await logout();
  };

  return (
    <>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="inherit"
        onClick={handleClick}
      >
        {currentUser && <Avatar src={currentUser.photoURL} />}
        {!currentUser && <Person />}
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!currentUser && <MenuItem onClick={onLogin}>Login</MenuItem>}
        {currentUser && <MenuItem onClick={onLogout}>Logout</MenuItem>}
      </Menu>
    </>
  );
}

export default Profile;
