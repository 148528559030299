import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {Link} from "@reach/router";
import PropTypes from "prop-types";

export const buildUrl = (uri, path) => {
  if (Boolean(path) && path.startsWith("/")) {
    return path;
  }
  const a = document.createElement("a");
  a.href = `${uri}/${path}`;
  return a.pathname;
};

function SubHeader({ uri, tabs }) {
  // TODO refactor this, find a way how to leverage router logic here (maybe using a context?)
  const selectedTab = tabs.findIndex(tab =>
    uri.startsWith(buildUrl(uri, tab.url))
  );
  return (
    <AppBar position="static" color="primary" elevation={0}>
      <Tabs value={Math.max(selectedTab, 0)} indicatorColor="secondary">
        {tabs.map(tab => (
          <Tab
            key={tab.label}
            label={tab.label}
            component={Link}
            to={tab.url}
          />
        ))}
      </Tabs>
    </AppBar>
  );
}

SubHeader.propTypes = {
  uri: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ).isRequired
};

export default SubHeader;
