import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/styles";
import PropTypes from "prop-types";
import LessonList from "../../lesson/LessonList";
import {IconButton} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import MoveIcon from "@material-ui/icons/DragIndicator";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  item: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  }
}));

const SectionListItem = ({ section, openForm, confirmDelete }) => {
  const classes = useStyles();

  const { title, id: sectionId, lessons } = section;

  return (
    <Paper className={classes.paper} key={sectionId}>
      <Typography gutterBottom variant="h5" className={classes.item}>
        {title}
        <div>
          <IconButton onClick={() => openForm(section)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => confirmDelete(section)}>
            <DeleteIcon />
          </IconButton>
          <IconButton>
            <MoveIcon />
          </IconButton>
        </div>
      </Typography>
      <LessonList lessons={lessons} section={section} />
    </Paper>
  );
};

SectionListItem.propTypes = {
  section: PropTypes.shape({
    id: PropTypes.string,
    courseId: PropTypes.string,
    title: PropTypes.string,
    position: PropTypes.number,
    lessons: PropTypes.any
  }),
  openForm: PropTypes.func.isRequired,
  confirmDelete: PropTypes.func.isRequired
};

export default SectionListItem;
