

export const findRole = (roleFilter, roles = []) => {
  const roleFilterValues = roleFilter.map(({ value }) => value);

  const filteredRoles = roles.filter(value => roleFilterValues.includes(value));

  // role types do not intersect
  return filteredRoles[0] || "";
};
