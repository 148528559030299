import MUIGrid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/styles";
import MUIBox from "@material-ui/core/Box/Box";
import MUIButton from "@material-ui/core/Button/Button";
import MUIPaper from "@material-ui/core/Paper/Paper";

export const Wrapper = withStyles(theme => ({
  root: {
    marginTop: theme.spacing(6)
  }
}))(MUIGrid);

export const SettingsActions = withStyles(theme => ({
  root: {
    width: "350px",
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column"
  }
}))(MUIBox);

export const Button = withStyles(theme => ({
  root: {
    marginTop: theme.spacing(1)
  }
}))(MUIButton);

export const Paper = withStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1)
  }
}))(MUIPaper);