import {themeDetailsFragment} from "../graphql";
import gql from "graphql-tag";
import {GET_THEMES} from "../ThemeList/graphql";
import {useMutation} from "@apollo/react-hooks";
import get from "lodash.get";

export const CREATE_THEME = gql`
  mutation CreateTheme($theme: NewThemeInput!) {
    createTheme(theme: $theme) {
      ...themeDetails
    }
  }
  ${themeDetailsFragment}
`;

export const useCreateThemeMutation = () => {
  const [createTheme] = useMutation(CREATE_THEME, {
    update: (proxy, { data: { createTheme: newTheme } }) => {
      const { themes } = proxy.readQuery({ query: GET_THEMES });
      proxy.writeQuery({
        query: GET_THEMES,
        data: { themes: [...themes, { ...newTheme }] }
      });
    }
  });

  return async ({ id, ...theme }) => {
    const newTheme = await createTheme({
      variables: { theme }
    });
    return get(newTheme, 'data.createTheme');
  }
};

export const UPDATE_THEME = gql`
  mutation UpdateTheme($theme: ThemeInput!) {
    updateTheme(theme: $theme) {
      ...themeDetails
    }
  }
  ${themeDetailsFragment}
`;

export const useUpdateThemeMutation = () => {
  const [updateTheme] = useMutation(UPDATE_THEME);

  return async theme =>
    await updateTheme({
      variables: { theme },
      update: proxy => {
        proxy.writeData({
          data: {
            selectedThemeId: null
          }
        });
      }
    });
};
