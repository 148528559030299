import React, { useState } from "react";
import PropTypes from "prop-types";
import RolesPicker from "../RolesPicker";
import { Wrapper, Button } from "./styledComponents";
import { TYPE_USERS } from "../../../common/types";

const UsersRolesForm = props => {
  const { onSubmit } = props;

  const [roles, setRoles] = useState([]);

  return (
    <Wrapper>
      <RolesPicker onChange={setRoles} />
      <Button
        variant="contained"
        color="primary"
        onClick={() => onSubmit(roles)}
        disabled={!Boolean(roles.length)}
      >
        Apply Changes
      </Button>
    </Wrapper>
  );
};

UsersRolesForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  users: TYPE_USERS.isRequired
};

export default UsersRolesForm;
