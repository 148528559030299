import React from "react";
import ActionButton from "./ActionButton";
import { Remove } from "@material-ui/icons";
import { useInsertAtomicBlock } from "../hooks";
import { atomicTypes as atomicType, mutability } from "../constants";

export default function AddDivider() {
  const insertAtomicBlock = useInsertAtomicBlock();
  return (
    <ActionButton
      label={"Add divider"}
      action={() =>
        insertAtomicBlock(atomicType.DIVIDER, mutability.IMMUTABLE, {})
      }
      icon={Remove}
    />
  );
}
