export const NEW_SECTION = {
  id: null,
  courseId: null,
  title: "",
  position: 0
};

export const buildFormValues = (defaultValues = {}) => {
  // TODO find batter way of finding objects intersection
  const { id, courseId, title, position } = {
    ...NEW_SECTION,
    ...defaultValues
  };
  return { id, courseId, title, position };
};

export const validateSectionForm = (values = {}) => {
  const errors = {};
  if (!values || !values.title || values.title.trim().length === 0) {
    errors.title = "Title must be set";
  }
  return errors;
};
