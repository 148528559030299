import {makeStyles} from "@material-ui/styles";
import Spinner from "../Spinner";
import React from "react";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative"
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: "rgba(0,0,0,0.7)",
    zIndex: "20",
    overflow: "hidden"
  }
}));

const Overlay = () => {
  const classes = useStyles();
  return (
    <div className={classes.overlay}>
      <Spinner />
    </div>
  );
};

export default Overlay;
