import { makeStyles } from "@material-ui/core";
import { TextField } from "../../shared/FormFields";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Modal from "../../shared/Modal";
import useForm from "../../../hooks/useForm";
import { buildFormValues, validateLessonForm } from "./utils";
import Overlay from "../../shared/Overlay";
import { preventDefault } from "../../../common/util";

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%"
  }
}));

const LessonForm = props => {
  const {
    modalId,
    modalTitle,
    onSubmit,
    onCancel,
    submitButtonLabel,
    cancelButtonLabel,
    lesson
  } = props;
  const classes = useStyles();

  const defaultValues = useMemo(() => buildFormValues(lesson), [lesson]);

  const form = useForm({
    defaultValues,
    submitHandler: onSubmit,
    validatorHandler: validateLessonForm,
    reinitialize: !Boolean(defaultValues.id)
  });

  const handleSubmit = preventDefault(async () => {
    const processed = await form.submit();
    if (processed) {
      onCancel();
    }
  });

  const handleCancel = preventDefault(() => {
    if (form.isSubmitting) {
      return;
    }
    onCancel();
    form.reset();
  });

  return (
    <Modal
      modalId={modalId}
      title={modalTitle}
      onConfirm={handleSubmit}
      onCancel={handleCancel}
      confirmButtonLabel={submitButtonLabel}
      cancelButtonLabel={cancelButtonLabel}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit} className={classes.form}>
        <TextField label="Title" {...form.field("title")} autoFocus />
        <TextField label="Description" {...form.field("description")} />
      </form>
      {form.isSubmitting && <Overlay />}
    </Modal>
  );
};

LessonForm.propTypes = {
  modalId: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  lesson: PropTypes.shape({})
};

LessonForm.defaultProps = {
  lesson: null,
  submitButtonLabel: "Submit",
  cancelButtonLabel: "Cancel"
};

export default LessonForm;
