import { EditorState } from "draft-js";

const onChange = options => newEditorState => {
  const { autoSave, setEditorState } = options;

  const handleChange = editorState => {
    const lastSelectionState = editorState.getSelection();
    const currentSelectionState = newEditorState.getSelection();
    const lastContentState = editorState.getCurrentContent();
    const currentContentState = newEditorState.getCurrentContent();

    // only selection is changed
    if (
      lastContentState.equals(currentContentState) &&
      !lastSelectionState.equals(currentSelectionState)
    ) {
      return EditorState.forceSelection(
        newEditorState,
        newEditorState.getSelection()
      );
    }

    autoSave(newEditorState);

    return newEditorState;
  };

  setEditorState(handleChange);
};

export default onChange;
