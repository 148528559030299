import gql from "graphql-tag";
import {useApolloClient, useQuery} from "@apollo/react-hooks";

export const sectionDetailsFragment = gql`
  fragment sectionDetails on Section {
    id
    courseId
    title
    position
  }
`;

export const SELECTED_SECTION_ID = gql`
  query SelectedSection {
    selectedSectionId @client
  }
`;
export const useSelectedSection = () => {
  const client = useApolloClient();
  const {
    data: { selectedSectionId }
  } = useQuery(SELECTED_SECTION_ID);

  return client.readFragment({
    id: `Section:${selectedSectionId}`,
    fragment: sectionDetailsFragment
  });
};

export const useSelectSectionMutation = () => {
  const client = useApolloClient();
  return section => {
    const { id: selectedSectionId } = section || { id: null };
    client.writeData({
      data: {
        selectedSectionId,
        selectedLessonId: null
      }
    });
  };
};
