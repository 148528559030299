import React from "react";
import { Typography } from "@material-ui/core";
import { Wrapper } from "./styledComponents";

const HomePage = () => (
  <Wrapper>
    <Typography variant="h5" component="h2">
      Your courses
    </Typography>
  </Wrapper>
);

export default HomePage;
