import { styled } from "@material-ui/styles";
import ButtonMUI from "@material-ui/core/Button";

export const Wrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "row"
}));

export const Button = styled(ButtonMUI)(() => ({
  alignSelf: "center"
}));
