import { useEffect, useRef, useState } from "react";

export const DEFAULT_FORM_OPTIONS = {
  defaultValues: {},
  submitHandler: Function.prototype,
  validatorHandler: Function.prototype,
  reinitialize: false
};

const useForm = (options = {}) => {
  const {
    defaultValues,
    submitHandler,
    validatorHandler,
    reinitialize,
    formRef
  } = {
    ...DEFAULT_FORM_OPTIONS,
    ...options
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isInitialized = useRef(false);

  useEffect(
    () => {
      if (!isInitialized.current) {
        isInitialized.current = true;
      } else {
        reset();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Object.values(defaultValues)
  );

  const setValue = (name, value) =>
    setFormValues({ ...formValues, [name]: value });

  const onFieldChange = fieldName => e => {
    const fieldValue = e.target.value;
    setValue(fieldName, fieldValue);
  };

  const submit = async () => {
    setIsSubmitting(true);
    const isValid = validate();
    if (isValid) {
      try {
        await submitHandler(formValues);
      } catch (e) {
        console.error(e);
        setIsSubmitting(false);
        return false;
      }
      if (reinitialize) {
        reset();
      }
    }
    setIsSubmitting(false);
    return isValid;
  };

  const validate = () => {
    const errors = validatorHandler(formValues) || {};
    setFormErrors(errors);
    return !hasErrors(errors);
  };

  const reset = () => {
    setFormValues({ ...defaultValues });
    setFormErrors({});
    setIsSubmitting(false);
  };

  const field = fieldName => ({
    value: formValues[fieldName] || "",
    onChange: onFieldChange(fieldName),
    error: formErrors[fieldName],
    name: fieldName
  });

  const hasErrors = errors => Object.keys(errors).length > 0;

  const formHandler = {
    formValues,
    setFormValues,
    setValue,
    formErrors,
    setFormErrors,
    hasErrors: () => hasErrors(formErrors),
    field,
    submit,
    validate,
    reset,
    isSubmitting
  };

  if (formRef) {
    formRef.current = formHandler;
  }

  return formHandler;
};

export default useForm;
