import Header from "./Header";
import React from "react";
import Sidebar from "./Sidebar";

import { routePropTypes } from "../../components/Route";
import CourseSubHeader from "./SubHeader/CourseSubHeader";
import UsersSubHeaderContainer from "./SubHeader/UsersSubHeader/UsersSubHeaderContainer";
import { AppBar, Content, Wrapper } from "./styledComponents";
import { useGlobalStyles } from "../../components/globalStyles";

const Page = props => {
  const { uri, children } = props;

  useGlobalStyles();

  const [open, setOpen] = React.useState(true);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Wrapper>
      <AppBar position="absolute" data-open={open}>
        <Header open={open} setOpen={setOpen} />
        <CourseSubHeader uri={uri} />
        <UsersSubHeaderContainer uri={uri} />
      </AppBar>
      <Sidebar open={open} handleDrawerClose={handleDrawerClose} />
      <Content component="main">{children}</Content>
    </Wrapper>
  );
};

Page.propTypes = {
  ...routePropTypes
};

export default Page;
