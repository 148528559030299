import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { GET_COURSE } from "../../course/CourseDetails/graphql";
import { sectionDetailsFragment } from "../graphql";

export const CREATE_SECTION = gql`
  mutation CreateSection($section: NewSectionInput!) {
    createSection(section: $section) {
      ...sectionDetails
    }
  }
  ${sectionDetailsFragment}
`;

export const useCreateSectionMutation = () => {
  const [createSection] = useMutation(CREATE_SECTION, {
    update: (proxy, { data: { createSection: newSection } }) => {
      const { course } = proxy.readQuery({
        query: GET_COURSE,
        variables: { id: newSection.courseId }
      });

      const sections = [...course.sections, { ...newSection, lessons: [] }];

      proxy.writeQuery({
        query: GET_COURSE,
        variables: { id: newSection.courseId },
        data: { course: { ...course, sections } }
      });
    }
  });

  return async ({ id, ...section }) => {
    await createSection({
      variables: { section }
    });
  };
};

export const UPDATE_SECTION = gql`
  mutation UpdateSection($section: SectionInput!) {
    updateSection(section: $section) {
      ...sectionDetails
    }
  }
  ${sectionDetailsFragment}
`;

export const useUpdateSectionMutation = () => {
  const [updateSection] = useMutation(UPDATE_SECTION);
  return async section => {
    await updateSection({
      variables: { section },
      update: proxy => {
        proxy.writeData({
          data: {
            selectedSectionId: null
          }
        });
      }
    });
  };
};
