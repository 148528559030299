import {styled} from "@material-ui/styles";
import {AppBar as AppBarMUI, Container as ContainerMUI} from "@material-ui/core";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  minHeight: "100%"
}));

export const AppBar = styled(AppBarMUI)(({ theme, "data-open": dataOpen }) => {
  const appBar = {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.lightColor,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  };

  const appBarShift = {
    marginLeft: theme.dimensions.sidebar,
    width: `calc(100% - ${theme.dimensions.sidebar})`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  };

  return dataOpen ? { ...appBar, ...appBarShift } : appBar;
});

export const Content = styled(ContainerMUI)(({ theme }) => ({
  flex: 1,
  padding: `0 ${theme.spacing(4)}px`,
  marginTop: theme.spacing(12)
}));
