import React from "react";
import PropTypes from "prop-types";
import { notEditable } from "../../../constants";
import LessonEditorPopup from "../../../LessonEditorPopup";
import ImageInlineActions from "../ImageInlineActions";

const ImageInlineToolbar = props => {
  const { referenceElement, imageBlockKey, show } = props;

  if (!referenceElement) {
    return null;
  }

  return (
    <LessonEditorPopup
      component={ImageInlineActions}
      componentProps={{ imageBlockKey }}
      referenceElement={referenceElement}
      placement="top"
      show={show}
      {...notEditable}
      modifiers={{
        flip: {
          enabled: false
        }
      }}
    />
  );
};

ImageInlineToolbar.propTypes = {
  referenceElement: PropTypes.object,
  imageBlockKey: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired
};

ImageInlineActions.defaultProps = {
  referenceElement: null
};

export default ImageInlineToolbar;
