import React from "react";
import BreadcrumbItem from "../BreadcrumbItem";
import PropTypes from "prop-types";
import {useSelectedTheme} from "../../../../components/theme/graphql";

const ThemeBreadcrumb = props => {
  const { url } = props;

  const { name } = useSelectedTheme() || { name: "..." };

  return <BreadcrumbItem title={name} url={url} />;
};

ThemeBreadcrumb.propTypes = {
  url: PropTypes.string.isRequired
};

export default ThemeBreadcrumb;
