import React from "react";
import {useCreateSectionMutation, useUpdateSectionMutation} from "./graphql";
import SectionForm from "./SectionForm";
import {MODALS} from "../../../common/constants";
import {useOpenModal} from "../../shared/Modal/graphql";
import {useSelectedCourse} from "../../course/graphql";
import {useSelectedSection} from "../graphql";

const modalId = MODALS.SECTION_FORM_MODAL;

const SectionFormContainer = () => {
  const createSection = useCreateSectionMutation();
  const updateSection = useUpdateSectionMutation();
  const openModal = useOpenModal();
  const course = useSelectedCourse();
  const section = useSelectedSection();

  const onCancel = () => openModal(modalId, false);

  const onCreateSection = data =>
    createSection({ ...data, courseId: course.id });

  const onUpdateSection = data =>
    updateSection({ ...data, courseId: course.id });

  const isUpdating = section && Boolean(section.id);

  const modalTitle = isUpdating
    ? `Edit section ${section.title}`
    : "Add new section";
  const submitButtonLabel = isUpdating ? "Update" : "Add";
  const onSubmit = isUpdating ? onUpdateSection : onCreateSection;

  return (
    <SectionForm
      modalId={modalId}
      modalTitle={modalTitle}
      onSubmit={onSubmit}
      onCancel={onCancel}
      submitButtonLabel={submitButtonLabel}
      section={section}
    />
  );
};

export default SectionFormContainer;
