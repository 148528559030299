import React from "react";
import PropTypes from "prop-types";
import Spinner from "../../shared/Spinner";
import {useGetCourse} from "./graphql";
import CourseSyllabus from "../CourseSyllabus";
import CourseSettings from "../CourseSettings";
import CourseActivity from "../CourseActivity";

const CourseDetailsContainer = props => {
  const { path, courseId: id, navigate } = props;
  const { loading, error, data: course } = useGetCourse(id);

  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return `Error! ${error.message}`;
  }

  switch (path) {
    case "syllabus":
      return <CourseSyllabus course={course} />;

    case "settings":
      return <CourseSettings course={course} />;

    case "activity":
      return <CourseActivity course={course} />;

    default:
      navigate("syllabus");
      return null;
  }
};

CourseDetailsContainer.propTypes = {
  courseId: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired
};

export default CourseDetailsContainer;
