const readFile = (file, withPreview = true) => {
  const reader = new FileReader();

  return new Promise(resolve => {
    reader.onload = () => {
      let content = reader.result.replace(/^data:(.*;base64,)?/, "");

      if (content.length % 4 > 0) {
        content += "=".repeat(4 - (content.length % 4));
      }

      const { name, size, type: contentType } = file;

      const data = { name, size, contentType, content };

      resolve(withPreview ? { ...data, preview: reader.result } : data);
    };
    reader.readAsDataURL(file);
  });
};

export default readFile;
