import React, { useEffect } from "react";
import Video from "../../../../shared/Video";

const LessonEditorBlockVideo = props => {
  const {
    block,
    blockProps: { addAsset, removeAsset }
  } = props;

  const src = block.getData().get("src");

  useEffect(() => {
    // only Firebase asset add to list
    if (src.startsWith("asset/")) {
      addAsset(src);
    }

    return () => removeAsset(src);
  }, [addAsset, removeAsset, src]);

  return <Video src={src} controls />;
};

export default LessonEditorBlockVideo;
