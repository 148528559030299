import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import {useGetCourse} from "../../components/course/CourseDetails/graphql";
import {useGetPageName} from "../graphql";
import {PAGE_NAMES} from "../util";

export const GET_SELECTED_COURSE = gql`
  query getSelectedCourse {
    selectedCourseId @client
  }
`;

export const useSelectedCourse = (pageNames = [PAGE_NAMES.COURSE]) => {
  // determine current page
  const { data: pageName } = useGetPageName();

  // if currentPage is COURSE find selected course details
  // selectedCourseId can be null
  const { data: selectedCourseId } = useQuery(GET_SELECTED_COURSE, {
    skip: !pageNames.includes(pageName)
  });

  const id = selectedCourseId && selectedCourseId.selectedCourseId;

  // when selectedCourseId is defined fetch selected course details
  const { data: course, loading, error } = useGetCourse(id, !Boolean(id));

  return {
    data: course,
    loading,
    error
  };
};
