import React from "react";
import PropTypes from "prop-types";
import {Divider, List, ListSubheader} from "@material-ui/core";
import CourseNavSection from "./CourseNavSection";

const CourseNav = ({ course }) => {
  const sections = course.sections && course.sections.map(section => {
    return (
      <CourseNavSection
        key={section.id}
        courseId={course.id}
        section={section}
      />
    );
  });

  return (
    <>
      <Divider />
      <List subheader={<ListSubheader>{course.title}</ListSubheader>}>
        <Divider />
        {sections}
      </List>
    </>
  );
};

CourseNav.propTypes = {
  course: PropTypes.object.isRequired
};

export default CourseNav;
