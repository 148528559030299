import {useSelectedCourse} from "../../graphql";
import BreadcrumbItem from "../BreadcrumbItem";
import React from "react";
import PropTypes from "prop-types";
import {PAGE_NAMES} from "../../../util";

const CourseBreadcrumb = ({ url }) => {
  const { data, loading, error } = useSelectedCourse([
    PAGE_NAMES.COURSE,
    PAGE_NAMES.LESSON
  ]);

  if (loading) {
    return <span>...</span>;
  }

  if (error || !data) {
    return "";
  }

  return <BreadcrumbItem url={url} title={data.title} />;
};

CourseBreadcrumb.propTypes = {
  url: PropTypes.string.isRequired
};

export default CourseBreadcrumb;
