import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/styles";
import SectionListItem from "./SectionListItem";
import React from "react";
import {Button} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "column"
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  }
}));

const SectionList = ({
  openForm,
  confirmDelete,
  sections
}) => {
  const classes = useStyles();

  const sectionItems = sections.map(section => (
    <SectionListItem
      key={section.id}
      section={section}
      openForm={openForm}
      confirmDelete={confirmDelete}
    />
  ));

  return (
    <div className={classes.wrapper}>
      {sectionItems}
      <div className={classes.actions}>
        <Button variant="contained" color="primary" onClick={() => openForm()}>
          Add section
        </Button>
      </div>
    </div>
  );
};

SectionList.propTypes = {
  openForm: PropTypes.func.isRequired,
  confirmDelete: PropTypes.func.isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

SectionList.defaultProps = {
  selectedSection: null
};

export default SectionList;
