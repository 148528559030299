import React, {useState} from "react";
import {MODALS} from "../../../common/constants";
import {useDeleteSectionMutation} from "./graphql";
import {useOpenModal} from "../../shared/Modal/graphql";
import SectionConfirmDeleteDialog from "./SectionConfirmDeleteDialog";
import {useSelectedSection} from "../graphql";

const modalId = MODALS.SECTION_DELETE_MODAL;

const SectionConfirmDeleteDialogContainer = () => {
  const deleteSection = useDeleteSectionMutation();
  const openModal = useOpenModal();
  const section = useSelectedSection();
  const [loading, setLoading] = useState(false);

  const onCancel = () => {
    if (loading) {
      return;
    }
    openModal(modalId, false);
  };

  const onConfirm = async () => {
    setLoading(true);
    await deleteSection(section.courseId, section.id);
    setLoading(false);
    onCancel();
  };

  return (
    <SectionConfirmDeleteDialog
      modalId={modalId}
      onConfirm={onConfirm}
      onCancel={onCancel}
      section={section}
      loading={loading}
    />
  );
};

export default SectionConfirmDeleteDialogContainer;
