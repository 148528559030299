import { useState } from "react";

const useListSelection = () => {
  const [selectedItems, setSelectedItems] = useState([]);

  return {
    selectItem: item => setSelectedItems(items => [...items, item]),
    deselectItem: item =>
      setSelectedItems(items =>
        items.filter(currentItem => currentItem !== item)
      ),
    selectedItems,
    clear: () => setSelectedItems([]),
    select: setSelectedItems
  };
};

export default useListSelection;
