import React from "react";
import UsersRolesForm from "./UsersRolesForm";
import onSubmit from "./onSubmit";
import { TYPE_USERS } from "../../../common/types";
import { useUpdateUsersRoles } from "./graphql";
import PropTypes from "prop-types";

const UsersRolesFormContainers = props => {
  const { users, removeUsersSelection } = props;

  const updateUsersRolesMutation = useUpdateUsersRoles();

  return (
    <UsersRolesForm
      {...props}
      onSubmit={onSubmit({ users, updateUsersRolesMutation, removeUsersSelection })}
    />
  );
};

UsersRolesFormContainers.propTypes = {
  users: TYPE_USERS.isRequired,
  removeUsersSelection: PropTypes.func.isRequired
};

export default UsersRolesFormContainers;
