import React from "react";
import Input from "@material-ui/core/Input";
import PropTypes from "prop-types";
import FormField from "./FormField";

const TextField = props => {
  const { label, error, ...inputProps } = props;

  return (
    <FormField label={label} name={inputProps.name} error={error}>
      <Input id={inputProps.name} {...inputProps} />
    </FormField>
  );
};

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string
};

TextField.defaultProps = {
  error: null
};

export default TextField;
