import React, {useState} from "react";
import LessonEditorModal from "../LessonEditorModal";
import {Button, TextField} from "@material-ui/core";
import PropTypes from "prop-types";
import {Close, Link} from "@material-ui/icons";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(2)
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
    width: "100%"
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));

const LinkDialog = props => {
  const { title, onSave, onClose, ...modalProps } = props;

  const classes = useStyles();
  const [url, setUrl] = useState("");

  return (
    <LessonEditorModal label={title} onClose={onClose} {...modalProps}>
      <form noValidate autoComplete="off" className={classes.form}>
        <TextField
          id="url"
          label="URL"
          value={url}
          onChange={({ target: { value } }) => setUrl(value)}
          className={classes.textField}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onMouseDown={mouseEvent => {
              mouseEvent.preventDefault();
              onSave(url);
            }}
          >
            <Link className={classes.icon} />
            Ok
          </Button>
          <Button
            variant="contained"
            className={classes.button}
            onMouseDown={() => onClose()}
          >
            <Close className={classes.icon} />
            Cancel
          </Button>
        </div>
      </form>
    </LessonEditorModal>
  );
};

LinkDialog.propTypes = {
  title: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default LinkDialog;
