import React, { useState } from "react";
import { useLessonEditor } from "../LessonEditorContext";
import { Link } from "@material-ui/icons";
import ActionButton from "./ActionButton";
import { LinkDialog } from "../LinkDialog";
import { useToggleLink } from "../hooks";

export default function AddLink() {
  const [isVisible, setVisibility] = useState(false);
  const { setReadOnly, focus } = useLessonEditor();
  const toggleLink = useToggleLink();

  const onSave = url => {
    toggleLink(url);
    onClose();
  };

  const onClose = () => {
    setVisibility(false);
    setTimeout(() => {
      setReadOnly(false);
      focus();
    }, 0);
  };

  return (
    <>
      <ActionButton
        label={"Add link"}
        action={() => {
          setReadOnly(true);
          setVisibility(true);
        }}
        icon={Link}
      />
      <LinkDialog
        title="Enter URL"
        open={isVisible}
        fullWidth
        maxWidth={"sm"}
        onSave={onSave}
        onClose={onClose}
      />
    </>
  );
}
