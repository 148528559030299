import React from "react";
import PropTypes from "prop-types";
import LessonList from "./LessonList";
import { useOpenModal } from "../../shared/Modal/graphql";
import { MODALS } from "../../../common/constants";
import { useSelectLessonMutation } from "../graphql";
import { useSelectSectionMutation } from "../../section/graphql";

const LessonListContainer = ({ lessons, section }) => {
  const selectLesson = useSelectLessonMutation();
  const selectSection = useSelectSectionMutation();
  const openModal = useOpenModal();

  const openForm = (lesson = null) => {
    selectSection(section);
    selectLesson(lesson);
    openModal(MODALS.LESSON_FORM_MODAL, true);
  };

  const confirmDelete = lesson => {
    selectSection(section);
    selectLesson(lesson);
    openModal(MODALS.LESSON_DELETE_MODAL, true);
  };

  return (
    <LessonList
      openForm={openForm}
      confirmDelete={confirmDelete}
      lessons={lessons}
    />
  );
};

LessonListContainer.propTypes = {
  lessons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  section: PropTypes.shape({}).isRequired
};

export default LessonListContainer;
