import React from "react";
import {Grid, InputLabel} from "@material-ui/core";
import ColorPicker from "../../shared/ColorPicker/ColorPicker";

const ThemeEditorColors = props => {
  const { form } = props;
  return (
      <Grid container spacing={2}>
        <Grid item>
          <InputLabel htmlFor="primary-color">Primary Color</InputLabel>
          <br />
          <ColorPicker {...form.field("colorPrimary")} />
        </Grid>
        <Grid item>
          <InputLabel htmlFor="secondary-color">Secondary Color</InputLabel>
          <br />
          <ColorPicker {...form.field("colorSecondary")} />
        </Grid>
      </Grid>
  );
};

export default ThemeEditorColors;
