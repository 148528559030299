import React from "react";
import { useGetDefaultTheme, useUpdateDefaultTheme } from "./graphql";
import Spinner from "../../shared/Spinner";
import ThemeEditor from "../ThemeEditor";
import { Wrapper } from "./styledComponents";
import { Typography } from "@material-ui/core";

const ProjectThemeContainer = () => {
  const { data: theme, loading, error } = useGetDefaultTheme();
  const updateDefaultTheme = useUpdateDefaultTheme();

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return `Error! ${error.message}`;
  }

  return (
    <Wrapper>
      <Typography variant="h5" component="h2">
        Project theme
      </Typography>
      <ThemeEditor theme={theme} onSave={updateDefaultTheme} />
    </Wrapper>
  );
};

export default ProjectThemeContainer;
