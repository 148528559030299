import BreadcrumbItem from "../BreadcrumbItem";
import React from "react";
import PropTypes from "prop-types";
import {useSelectedSection} from "../../../../components/section/graphql";

const SectionBreadcrumb = ({ url }) => {
  const { title } = useSelectedSection() || { title: "..." };

  return <BreadcrumbItem url={url} title={title} />;
};

SectionBreadcrumb.propTypes = {
  url: PropTypes.string.isRequired
};

export default SectionBreadcrumb;
