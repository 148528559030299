import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {GET_COURSES} from "../CourseList/graphql";
import {courseDetailsFragment} from "../graphql";

export const CREATE_COURSE = gql`
  mutation CreateCourse($course: NewCourseInput!) {
    createCourse(course: $course) {
      ...courseDetails
    }
  }
  ${courseDetailsFragment}
`;

export const useCreateCourseMutation = () => {
  const [createCourse] = useMutation(CREATE_COURSE, {
    update: (proxy, { data: { createCourse: newCourse } }) => {
      const { courses } = proxy.readQuery({ query: GET_COURSES });
      proxy.writeQuery({
        query: GET_COURSES,
        data: { courses: [...courses, { ...newCourse, sections: [] }] }
      });
    }
  });

  return async ({ id, ...course }) =>
    await createCourse({
      variables: { course }
    });
};
