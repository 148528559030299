import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { lessonDetailsFragment } from "../graphql";
import { GET_COURSE } from "../../course/CourseDetails/graphql";
import { buildTranscript } from "./utils";

export const CREATE_LESSON = gql`
  mutation CreateLesson($lesson: NewLessonInput!) {
    createLesson(lesson: $lesson) {
      ...lessonDetails
    }
  }
  ${lessonDetailsFragment}
`;

export const useCreateLessonMutation = () => {
  const [createLesson] = useMutation(CREATE_LESSON, {
    update: (proxy, { data: { createLesson: newLesson } }) => {
      const { course } = proxy.readQuery({
        query: GET_COURSE,
        variables: { id: newLesson.courseId }
      });

      const sections = course.sections.map(section => {
        if (section.id === newLesson.sectionId) {
          return {
            ...section,
            lessons: [...section.lessons, newLesson]
          };
        }

        return section;
      });

      proxy.writeQuery({
        query: GET_COURSE,
        variables: { id: newLesson.courseId },
        data: { course: { ...course, sections } }
      });
    }
  });

  return async data => {
    const { id, ...lesson } = data;
    if (!lesson.transcript) {
      lesson.transcript = buildTranscript();
    }
    return await createLesson({
      variables: { lesson }
    });
  };
};

export const UPDATE_LESSON = gql`
  mutation UpdateLesson($lesson: LessonInput!) {
    updateLesson(lesson: $lesson) {
      ...lessonDetails
    }
  }
  ${lessonDetailsFragment}
`;

export const useUpdateLessonMutation = () => {
  const [updateLesson] = useMutation(UPDATE_LESSON);

  return async lesson => {
    delete lesson.transcript;
    return await updateLesson({
      variables: { lesson },
      update: proxy => {
        proxy.writeData({
          data: {
            selectedLessonId: null
          }
        });
      }
    });
  };
};
