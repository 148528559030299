import React from "react";
import { Wrapper } from "./styledComponents";
import { useGlobalStyles } from "../../components/globalStyles";

const Page = props => {
  const { children } = props;

  useGlobalStyles();

  return <Wrapper>{children}</Wrapper>;
};

export default Page;
