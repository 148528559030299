import gql from "graphql-tag";
import {courseDetailsFragment, useSelectCourseMutation} from "../graphql";
import {useQuery} from "@apollo/react-hooks";
import {sectionDetailsFragment} from "../../section/graphql";
import {lessonDetailsFragment} from "../../lesson/graphql";

export const GET_COURSE = gql`
  query GetCourse($id: ID!) {
    course(id: $id) {
      ...courseDetails
      sections {
        ...sectionDetails
        lessons {
          ...lessonDetails
        }
      }
    }
  }
  ${courseDetailsFragment}
  ${sectionDetailsFragment}
  ${lessonDetailsFragment}
`;

export const useGetCourse = (id, skip = false) => {
  const selectCourse = useSelectCourseMutation();
  const { data, loading, error } = useQuery(GET_COURSE, {
    variables: { id },
    skip
  });

  if (!loading && data && data.course) {
    selectCourse(data.course);
  }
  return { data: data ? data.course : data, loading, error };
};
