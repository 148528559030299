import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Button from "@material-ui/core/Button";
import {Grid, makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%"
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  }
}));

export default function ThemeEditorFonts(props) {
  const { form } = props;

  const classes = useStyles();

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Button variant="contained" color="primary">
          Upload font <CloudUploadIcon className={classes.rightIcon} />
        </Button>
      </Grid>
      <Grid item>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="headerFont">Heading Font</InputLabel>
          <Select {...form.field("headerFont")}>
            <MenuItem value="inherit">Default</MenuItem>
            <MenuItem value="'Roboto', sans-serif">Roboto</MenuItem>
            <MenuItem value="'Open Sans', sans-serif">Open Sans</MenuItem>
            <MenuItem value="'Lato', sans-serif">Lato</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="bodyFont">Body Font</InputLabel>
          <Select {...form.field("bodyFont")}>
            <MenuItem value="inherit">Default</MenuItem>
            <MenuItem value="'Roboto', sans-serif">Roboto</MenuItem>
            <MenuItem value="'Open Sans', sans-serif">Open Sans</MenuItem>
            <MenuItem value="'Lato', sans-serif">Lato</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="quoteFont">Quote Font</InputLabel>
          <Select {...form.field("quoteFont")}>
            <MenuItem value="inherit">Default</MenuItem>
            <MenuItem value="'Roboto', sans-serif">Roboto</MenuItem>
            <MenuItem value="'Open Sans', sans-serif">Open Sans</MenuItem>
            <MenuItem value="'Lato', sans-serif">Lato</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}
