import gql from "graphql-tag";
import {useApolloClient, useQuery} from "@apollo/react-hooks";
import {themeDetailsFragment} from "../theme/graphql";

export const lessonDetailsFragment = gql`
  fragment lessonDetails on Lesson {
    id
    courseId
    sectionId
    title
    description
  }
`;

export const SELECTED_LESSON_ID = gql`
  query SelectedLessonId {
    selectedLessonId @client
  }
`;

export const useSelectedLesson = () => {
  const client = useApolloClient();
  const {
    data: { selectedLessonId }
  } = useQuery(SELECTED_LESSON_ID);

  return client.readFragment({
    id: `Lesson:${selectedLessonId}`,
    fragment: lessonDetailsFragment
  });
};

export const useSelectLessonMutation = () => {
  const client = useApolloClient();
  return lesson => {
    const { id: selectedLessonId } = lesson || { id: null };
    client.writeData({
      data: {
        selectedLessonId
      }
    });
    if (lesson && lesson.sectionId) {
      client.writeData({
        data: {
          selectedSectionId: lesson.sectionId
        }
      });
    }
  };
};

export const GET_LESSON = gql`
  query GetLesson($courseId: ID!, $sectionId: ID!, $lessonId: ID!) {
    lesson(courseId: $courseId, sectionId: $sectionId, lessonId: $lessonId) {
      ...lessonDetails
      transcript
      theme {
        ...themeDetails
      }
    }
  }
  ${lessonDetailsFragment}
  ${themeDetailsFragment}
`;
