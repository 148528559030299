import React from "react";
import {Typography} from "@material-ui/core";
import {Wrapper} from "./styledComponents";

const CourseActivity = props => {
  return (
    <Wrapper>
      <Typography variant="h5" component="h2">
        Course Activity
      </Typography>
      <Typography>Brian Fountain revised Lesson 1</Typography>
    </Wrapper>
  );
};

export default CourseActivity;
