import React, { useCallback, useEffect, useRef, useState } from "react";
import LessonEditorContext from "./LessonEditorContext";
import {
  useGetLesson,
  useUpdateLessonTranscriptMutation,
  useUploadFile
} from "./graphql";
import {
  buildEditorState,
  getBlockRendererFn,
  getBlockRenderMap,
  getBlockStyleFn,
  handleDroppedFiles,
  handleKeyCommand,
  handlePastedFiles,
  onChange
} from "./helpers";
import { convertToRaw, EditorState } from "draft-js";
import LessonEditor from "./LessonEditor";
import PropTypes from "prop-types";
import Spinner from "../../shared/Spinner";
import { navigate } from "@reach/router";
import { useBuildCourseTheme } from "../../../hooks/useBuildCourseTheme";
import get from "lodash.get";
import handleReturn from "./helpers/handleReturn";
import useAssets from "./hooks/useAssets";
import readFile from "../../../common/readFile";
import useMediaModal from "./hooks/useMediaModal";
import debounce from "lodash.debounce";

const convertEditorStateToTranscript = editorState =>
  JSON.stringify(convertToRaw(editorState.getCurrentContent()));

const LessonEditorContainer = props => {
  const { courseId, sectionId, lessonId } = props;

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [readOnly, setReadOnly] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const editorRef = useRef(null);
  const { assets, addAsset, removeAsset } = useAssets();
  const {
    openImageForm,
    openVideoForm,
    closeMediaForm,
    mediaModalProps
  } = useMediaModal(setReadOnly);

  const updateLessonMutation = useUpdateLessonTranscriptMutation();
  const uploadFile = useUploadFile();

  const updateLesson = transcript =>
    updateLessonMutation({
      id: lessonId,
      sectionId,
      courseId,
      transcript,
      assets
    });

  const autoSave = useCallback(
    debounce(async editorState => {
      const lessonTranscript = convertEditorStateToTranscript(editorState);

      await updateLesson(lessonTranscript);
    }, 3000),
    []
  );

  const { data: lesson, loading, error } = useGetLesson(
    courseId,
    sectionId,
    lessonId
  );

  // hydrate editor state from lessonInput
  useEffect(() => {
    const transcript = get(lesson, "transcript");
    if (Boolean(transcript)) {
      setEditorState(buildEditorState(transcript));
    }
  }, [lesson]);

  const styles = get(lesson, "theme.styles", {});
  const courseClasses = useBuildCourseTheme(styles);

  if (loading) return <Spinner />;
  if (error) return <div>Error! ${error.message}</div>;

  const save = async () => {
    const lessonTranscript = convertEditorStateToTranscript(editorState);

    await updateLesson(lessonTranscript);

    navigate(`/courses/${lesson.courseId}/syllabus`);
  };

  const focus = () => {
    editorRef.current.focus();
  };

  const setEditorStateHandler = onChange({
    autoSave,
    setEditorState
  });

  const contextValue = {
    editorState,
    setEditorState: setEditorStateHandler,
    readOnly,
    setReadOnly,
    focus,
    save,
    courseClasses,
    lessonId,
    addAsset,
    removeAsset,
    openImageForm,
    openVideoForm,
    closeMediaForm,
    mediaModalProps,
    setShowOverlay,
    showOverlay
  };

  const editorProps = {
    blockRenderMap: getBlockRenderMap(),
    blockRendererFn: getBlockRendererFn({
      editorState,
      setEditorState: setEditorStateHandler,
      addAsset,
      removeAsset
    }),
    blockStyleFn: getBlockStyleFn(courseClasses),
    handleKeyCommand: handleKeyCommand(setEditorState),
    handleReturn: handleReturn(setEditorState),
    handlePastedFiles: handlePastedFiles({
      editorState,
      setEditorState,
      readFile,
      uploadFile,
      setShowOverlay,
      setReadOnly
    }),
    handleDroppedFiles: handleDroppedFiles({
      editorState,
      setEditorState,
      readFile,
      uploadFile,
      setShowOverlay,
      setReadOnly
    })
  };

  return (
    <LessonEditorContext.Provider value={contextValue}>
      <LessonEditor ref={editorRef} editorProps={editorProps} lesson={lesson} />
    </LessonEditorContext.Provider>
  );
};

LessonEditorContainer.propTypes = {
  courseId: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
  lessonId: PropTypes.string.isRequired
};

export default LessonEditorContainer;
