import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {SketchPicker} from "react-color";

const useStyles = makeStyles(theme => ({
  color: {
    width: "22px",
    height: "22px",
    borderRadius: "2px"
  },
  swatch: {
    padding: "5px",
    background: "#fff",
    borderRadius: "1px",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "inline-block",
    cursor: "pointer"
  },
  popover: {
    position: "absolute",
    zIndex: "2"
  },
  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px"
  }
}));

const ColorPicker = props => {
  const { value, onChange } = props;

  const classes = useStyles();

  const [displayPicker, setDisplayPicker] = React.useState(false);

  const handleChange = color => {
    onChange({ target: { value: color.hex } });
  };

  return (
    <div>
      <div className={classes.swatch} onClick={() => setDisplayPicker(true)}>
        <div
          className={classes.color}
          style={{
            background: value
          }}
        />
      </div>
      {displayPicker ? (
        <div className={classes.popover}>
          <div
            className={classes.cover}
            onClick={() => setDisplayPicker(false)}
          />
          <SketchPicker color={value} onChangeComplete={handleChange} />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
