import {BLOCK_TYPES} from "../constants";
import {RichUtils} from "draft-js";

const handleKeyCommand = setEditorState => (command, editorState) => {
    // convert block type
    if (command.indexOf("convertTo-") === 0) {
        const newBlockType = command.substr("convertTo-".length);
        if (newBlockType === BLOCK_TYPES.ATOMIC) {
            return "handled";
        }
        setEditorState(RichUtils.toggleBlockType(editorState, newBlockType));
        return "handled";
    }
    // bold, italic
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
        setEditorState(newState);
        return "handled";
    }
    // regular keys
    return "not-handled";
};

export default handleKeyCommand;
