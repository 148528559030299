import React from "react";
import PropTypes from "prop-types";
import { TablePagination } from "./styledComponents";
import PaginationActions from "./PaginationActions";
import onPageChange from "./onPageChange";
import onRowsPerPageChange from "./onRowsPerPageChange";

const Pagination = props => {
  const { count, rowsPerPage, page, onChange, ...rest } = props;

  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      colSpan={3}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      SelectProps={{
        inputProps: { "aria-label": "Rows per page" },
        native: true
      }}
      onChangePage={onPageChange({ onChange, rowsPerPage })}
      onChangeRowsPerPage={onRowsPerPageChange({ onChange, rowsPerPage })}
      ActionsComponent={PaginationActions}
      {...rest}
    />
  );
};

Pagination.propTypes = {
  onChange: PropTypes.func.isRequired,
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number
};

Pagination.defaultProps = {
  count: 0,
  rowsPerPage: 5,
  page: 0
};

export default Pagination;
