import gql from "graphql-tag";
import {useApolloClient, useQuery} from "@apollo/react-hooks";

export const themeDetailsFragment = gql`
  fragment themeDetails on Theme {
    id
    name
    description
    headerLeft
    headerRight
    footerLeft
    footerRight
    styles {
      headerFont
      bodyFont
      quoteFont
      colorPrimary
      colorSecondary
    }
  }
`;

export const SELECTED_THEME_ID = gql`
  query SelectedThemeId {
    selectedThemeId @client
  }
`;

export const useSelectedTheme = () => {
  const client = useApolloClient();

  const {
    data: { selectedThemeId }
  } = useQuery(SELECTED_THEME_ID);

  return client.readFragment({
    id: `Theme:${selectedThemeId}`,
    fragment: themeDetailsFragment
  });
};

export const useSelectThemeMutation = () => {
  const client = useApolloClient();

  return theme => {
    const { id: selectedThemeId } = theme || { id: null };
    client.writeData({
      data: {
        selectedThemeId
      }
    });
  };
};
