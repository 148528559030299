import {convertFromRaw, EditorState} from "draft-js";
import decorator from "../decorators";

const buildEditorState = transcript => {
  if (!transcript) {
    return EditorState.createEmpty(decorator);
  }

  return EditorState.createWithContent(
    convertFromRaw(JSON.parse(transcript)),
    decorator
  );
};

export default buildEditorState;
