import { getCurrentBlock, handlePastedFiles } from "./index";
import { EditorState, Modifier } from "draft-js";

const spliceCurrentBlock = (editorState, selection) => {
  const contentStateSplicedBlock = Modifier.splitBlock(
    editorState.getCurrentContent(),
    selection
  );

  // block is empty and ready for media content
  if (getCurrentBlock(editorState).getLength() === 0) {
    return contentStateSplicedBlock;
  }

  return Modifier.splitBlock(contentStateSplicedBlock, selection);
};

const handleDroppedFiles = options => (selection, files) => {
  const { editorState } = options;

  const editorStateWithNewBlock = EditorState.push(
    editorState,
    spliceCurrentBlock(editorState, selection),
    "split-block"
  );

  handlePastedFiles({ ...options, editorState: editorStateWithNewBlock })(
    files
  );

  return "handled";
};

export default handleDroppedFiles;
