import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

const UPDATE_USERS_ROLES_MUTATION = gql`
  mutation updateUsersRoles($usersRoles: UsersRolesInput!) {
    updateUsersRoles(usersRoles: $usersRoles) {
      id
      roles
    }
  }
`;

export const useUpdateUsersRoles = () => {
  const [updateUsersRoles] = useMutation(UPDATE_USERS_ROLES_MUTATION);

  return async options => {
    const { users, roles } = options;

    const usersRoles = users.map(({ id }) => ({ id, roles }));

    return await updateUsersRoles({
      variables: { usersRoles: { users: usersRoles } }
    });
  };
};
