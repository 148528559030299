import { styled } from "@material-ui/styles";
import { Button as ButtonMUI } from "@material-ui/core";

export const Wrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(6)
}));

export const Button = styled(ButtonMUI)(({ theme }) => ({
  marginBottom: theme.spacing(1)
}));
