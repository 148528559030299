import React from "react";
import {preventDefault} from "../../../common/util";
import Modal from "../../shared/Modal";
import {Box, Typography} from "@material-ui/core";
import PropTypes from "prop-types";
import {TYPE_THEME} from "../../../common/types";
import Overlay from "../../shared/Overlay";

const ThemeConfirmDeleteDialog = props => {
  const { modalId, onConfirm, onCancel, theme, loading } = props;
  const handleConfirm = preventDefault(() => onConfirm());
  const handleCancel = preventDefault(() => onCancel());

  const { name, description } = theme || {};

  return (
    <Modal
      modalId={modalId}
      title="Are you sure you want to delete theme?"
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      confirmButtonLabel="Yes"
      cancelButtonLabel="No"
      maxWidth="md"
      fullWidth
    >
      <Typography component="span">
        <Box fontWeight="fontWeightBold">{name}</Box>
      </Typography>
      <Typography component="p">{description}</Typography>
      {loading && <Overlay />}
    </Modal>
  );
};

ThemeConfirmDeleteDialog.propTypes = {
  modalId: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  theme: TYPE_THEME,
  loading: PropTypes.bool.isRequired,
};

ThemeConfirmDeleteDialog.defaultProps = {
  theme: null
};

export default ThemeConfirmDeleteDialog;
