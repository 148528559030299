import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Router from "./AppRouter";
import FirebaseProvider from "./firebase";
import { theme } from "./theme";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <FirebaseProvider>
        <Router />
      </FirebaseProvider>
    </ThemeProvider>
  );
};
export default App;
